import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { HealthSummaryResponse } from "shared/api/healthScores/models";
import { ActivityCard } from "./ActivityCard";
import {
  determinePerformanceLevel,
  getGradientForScore,
  getFriendlyNameOfState,
} from "./formatters";
import { AverageRestingHeartRateCard } from "./AverageRestingHeartRateCard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ReadinessDetailsCardProps {
  readinessInsights: HealthSummaryResponse | null;
  open: boolean;
  onClose: () => void;
}

export const ReadinessDetailsCard: React.FC<ReadinessDetailsCardProps> = ({
  readinessInsights,
  open,
  onClose,
}) => {
  if (!readinessInsights) return null;

  const walkingStrainCapacity = readinessInsights.factors.find(
    (factor) => factor.name === "walking_strain_capacity"
  );

  const exerciseStrainCapacity = readinessInsights.factors.find(
    (factor) => factor.name === "exercise_strain_capacity"
  );

  const mentalRecovery = readinessInsights.factors.find(
    (factor) => factor.name === "mental_recovery"
  );

  const physicalRecovery = readinessInsights.factors.find(
    (factor) => factor.name === "physical_recovery"
  );

  const heartRateVariability = readinessInsights.factors.find(
    (factor) => factor.name === "heart_rate_variability"
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Readiness details
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={readinessInsights.averageRestingHeartRate ? 6 : 12}>
            <ActivityCard
              title="Daily readiness"
              value={Math.floor(readinessInsights.overallScore ?? 0)}
              target={100}
              unit=""
              gradient={getGradientForScore(readinessInsights.overallState)}
              overrideText={`${Math.floor(
                readinessInsights.overallScore ?? 0
              )} - ${getFriendlyNameOfState(readinessInsights.overallState)}`}
            />
          </Grid>
          {readinessInsights?.averageRestingHeartRate &&
            readinessInsights?.averageRestingHeartRate > 0 && (
              <Grid item xs={6}>
                <AverageRestingHeartRateCard
                  averageRestingHeartRate={
                    readinessInsights.averageRestingHeartRate ?? 0
                  }
                />
              </Grid>
            )}
          <Grid item xs={6}>
            <ActivityCard
              title="Walking strain capacity"
              value={Math.floor((walkingStrainCapacity?.value ?? 0) * 100)}
              target={Math.floor((walkingStrainCapacity?.goal ?? 0) * 100)}
              gradient={getGradientForScore(walkingStrainCapacity?.state ?? "")}
              overrideText={getFriendlyNameOfState(
                walkingStrainCapacity?.state ?? ""
              )}
              removeBottomText
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Exercise strain capacity"
              value={Math.floor((exerciseStrainCapacity?.value ?? 0) * 100)}
              target={Math.floor((exerciseStrainCapacity?.goal ?? 0) * 100)}
              gradient={getGradientForScore(
                exerciseStrainCapacity?.state ?? ""
              )}
              overrideText={getFriendlyNameOfState(
                exerciseStrainCapacity?.state ?? ""
              )}
              removeBottomText
            />
          </Grid>

          {mentalRecovery?.value ? (
            <Grid item xs={6}>
              <ActivityCard
                title="Mental recovery"
                value={Math.floor((mentalRecovery?.value ?? 0) * 100)}
                target={Math.floor((mentalRecovery?.goal ?? 0) * 100)}
                gradient={getGradientForScore(mentalRecovery?.state ?? "")}
                overrideText={getFriendlyNameOfState(
                  mentalRecovery?.state ?? ""
                )}
                removeBottomText
              />
            </Grid>
          ) : null}

          {physicalRecovery?.value ? (
            <Grid item xs={6}>
              <ActivityCard
                title="Physical recovery"
                value={Math.floor((physicalRecovery?.value ?? 0) * 100)}
                target={Math.floor((physicalRecovery?.goal ?? 0) * 100)}
                gradient={getGradientForScore(physicalRecovery?.state ?? "")}
                overrideText={getFriendlyNameOfState(
                  physicalRecovery?.state ?? ""
                )}
                removeBottomText
              />
            </Grid>
          ) : null}

          {heartRateVariability?.value ? (
            <Grid item xs={6}>
              <ActivityCard
                title="Heart rate variability"
                value={Math.floor((heartRateVariability?.value ?? 0) * 100)}
                target={Math.floor((heartRateVariability?.goal ?? 0) * 100)}
                gradient={getGradientForScore(
                  heartRateVariability?.state ?? ""
                )}
                overrideText={getFriendlyNameOfState(
                  heartRateVariability?.state ?? ""
                )}
                removeBottomText
              />
            </Grid>
          ) : null}
        </Grid>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#6C5CE7",
              mb: 2,
              fontWeight: 600,
              fontSize: "1.1rem",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LightbulbOutlinedIcon sx={{ fontSize: "1.2rem" }} />
            Insight
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            {readinessInsights.overallInsight}
          </Typography>
          <Link
            href="https://learn.sahha.ai/a-28-readiness-score-explained"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about readiness scores. Powered by Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
