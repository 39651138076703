import React from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { HealthSummaryResponse } from "shared/api/healthScores/models";
import { ActivityCard } from "./ActivityCard";
import {
  determinePerformanceLevel,
  getGradientForScore,
  getFriendlyNameOfState,
} from "./formatters";
import { SleepCard } from "./SleepCard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SleepDetailsCardProps {
  sleepInsights: HealthSummaryResponse | null;
  open: boolean;
  onClose: () => void;
}

export const SleepDetailsCard: React.FC<SleepDetailsCardProps> = ({
  sleepInsights,
  open,
  onClose,
}) => {
  if (!sleepInsights) return null;

  const sleepDuration = sleepInsights.factors.find(
    (factor) => factor.name === "sleep_duration"
  );

  const sleepRegularity = sleepInsights.factors.find(
    (factor) => factor.name === "sleep_regularity"
  );

  const sleepDebt = sleepInsights.factors.find(
    (factor) => factor.name === "sleep_debt"
  );

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Sleep details
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ActivityCard
              title="Overall sleep score"
              value={Math.floor(sleepInsights.overallScore ?? 0)}
              target={100}
              gradient={getGradientForScore(sleepInsights.overallState)}
              overrideText={`${Math.floor(
                sleepInsights.overallScore ?? 0
              )} - ${getFriendlyNameOfState(sleepInsights.overallState)}`}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityCard
              title="Sleep regularity"
              value={Math.floor(sleepRegularity?.value ?? 0)}
              target={Math.floor(sleepRegularity?.goal ?? 0)}
              gradient={getGradientForScore(sleepRegularity?.state ?? "")}
              overrideText={getFriendlyNameOfState(
                sleepRegularity?.state ?? ""
              )}
              removeBottomText
            />
          </Grid>
          <Grid item xs={6}>
            <SleepCard
              title="Sleep duration"
              value={Math.floor(sleepDuration?.value ?? 0)}
              target={Math.floor(sleepDuration?.goal ?? 0)}
              gradient={getGradientForScore(sleepDuration?.state ?? "")}
            />
          </Grid>
          <Grid item xs={6}>
            <SleepCard
              title="Sleep debt"
              value={Math.floor((sleepDebt?.value ?? 0) * 60)}
              target={Math.floor((sleepDebt?.value ?? 0) * 60)}
              gradient={getGradientForScore(sleepDebt?.state ?? "")}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            backgroundColor: "#f8f9ff",
            border: "1px solid rgba(108, 92, 231, 0.1)",
            boxShadow: "0 4px 12px rgba(108, 92, 231, 0.08)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#6C5CE7",
              mb: 2,
              fontWeight: 600,
              fontSize: "1.1rem",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LightbulbOutlinedIcon sx={{ fontSize: "1.2rem" }} />
            Insight
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "0.95rem",
              color: "#444",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            {sleepInsights.overallInsight}
          </Typography>
          <Link
            href="https://learn.sahha.ai/a-29-sleep-score-explained"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#6C5CE7",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "0.85rem",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Learn more about sleep scores. Powered by Sahha.ai
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
