import { FC, useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import {
  AdminSideNavigation,
  AdminWorkplaceWellbeingSideNavigation,
} from "./AdminSideNavigation";
import {
  AthleteBottomNavigation,
  EmployeeBottomNavigation,
} from "./AthleteSideNavigation";

interface SidebarProps {
  isOpenSidebar: boolean;
  closeSidebar?: () => void;
  openSidebar?: () => void;
}

const Sidebar: FC<SidebarProps> = ({
  isOpenSidebar,
  closeSidebar,
  openSidebar,
}) => {
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const hasRole = useTypedSelector(userSelectors.hasRole);
  const organisationType = useTypedSelector(userSelectors.organisationType);

  const [isMobileApp, setIsMobileApp] = useState(false);

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  useEffect(() => {
    checkDeviceIsMobileApp();
  }, []);

  if (!hasRole) {
    return null;
  }

  if (isAdminOrCoach && organisationType !== "WorkplaceWellbeing") {
    return (
      <AdminSideNavigation
        isOpenSidebar={isOpenSidebar}
        openSidebar={openSidebar}
      />
    );
  }

  if (isMobileApp && organisationType === "WorkplaceWellbeing") {
    return <EmployeeBottomNavigation />;
  }

  if (isAdminOrCoach && organisationType === "WorkplaceWellbeing") {
    return (
      <AdminWorkplaceWellbeingSideNavigation
        isOpenSidebar={isOpenSidebar}
        openSidebar={openSidebar}
      />
    );
  }

  if (!isAdminOrCoach && organisationType === "WorkplaceWellbeing") {
    return <EmployeeBottomNavigation />;
  }

  return <AthleteBottomNavigation />;
};

export { Sidebar };
