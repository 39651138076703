import { createApi } from "@reduxjs/toolkit/query/react";
import {
  UserPersonalRecord,
  CreateUserPersonalRecordParams,
  UpdateUserPersonalRecordParams,
  GetUserPersonalRecordsParams,
} from "./models";
import { baseQuery } from "../baseQuery";

export const userPersonalRecordsApi = createApi({
  baseQuery,
  reducerPath: "userPersonalRecordsApi",
  tagTypes: ["UserPersonalRecords"],
  endpoints: (builder) => ({
    getUserPersonalRecords: builder.query<
      UserPersonalRecord[],
      GetUserPersonalRecordsParams
    >({
      query: (params) => ({
        url: "/user-personal-records",
        method: "GET",
        params,
      }),
      providesTags: ["UserPersonalRecords"],
    }),
    createUserPersonalRecord: builder.mutation<
      UserPersonalRecord,
      CreateUserPersonalRecordParams
    >({
      query: (body) => ({
        url: "/user-personal-records",
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserPersonalRecords"],
    }),
    updateUserPersonalRecord: builder.mutation<
      UserPersonalRecord,
      UpdateUserPersonalRecordParams
    >({
      query: ({ id, ...body }) => ({
        url: `/user-personal-records/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UserPersonalRecords"],
    }),
    deleteUserPersonalRecord: builder.mutation<void, number>({
      query: (id) => ({
        url: `/user-personal-records/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserPersonalRecords"],
    }),
  }),
});

export const {
  useGetUserPersonalRecordsQuery,
  useLazyGetUserPersonalRecordsQuery,
  useCreateUserPersonalRecordMutation,
  useUpdateUserPersonalRecordMutation,
  useDeleteUserPersonalRecordMutation,
} = userPersonalRecordsApi;
