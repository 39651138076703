import { textTransform } from "@mui/system";
import { Colors } from "../Colors";

const tagContainerStyle = {
  width: "auto",
  height: "20px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "140%,",
  textTransform: "uppercase",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2.5px 8px",
};

const tagBodyStyle = {
  width: "6px",
  height: "6px",
  borderRadius: "50px",
  mr: "8px",
};

export const sharedStyles = {
  containers: {
    signIn: {
      grid: {
        flex: 1,
        display: "flex",
        bgcolor: `${Colors.gray[100]}`,
        padding: `4em`,
      },
      form: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "inherit",
      },
      logo: {
        width: 200,
        height: 200,
      },
      link: {
        textAlign: "center",
        mb: 3,
        mt: 3,
        cursor: "pointer",
      },
      testimonialBlock: {
        border: "1px solid white",
        position: "absolute",
        bottom: "2em",
        left: "50%",
        transform: "translateX(-50%)",
        width: "90%",
        height: "30%",
        padding: "24px",
        background: `radial-gradient(106.73% 247.34% at 4.46% 3.08%, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0.04) 100%)`,
        backdropFilter: "blur(30px)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    },
    sidebar: {
      content: {
        width: { xs: "100%", md: "calc(100% - 220px)" },
        position: "absolute",
        top: "0px",
        right: "0px",
        minHeight: "100vh",
        padding: "62px 32px 24px",
        bgcolor: `${Colors.gray[200]}`,
      },
      contentFullscreen: {
        width: "100%",
        position: "absolute",
        top: "0px",
        right: "0px",
        minHeight: "100vh",
        padding: "62px 32px 24px",
        bgcolor: `${Colors.gray[200]}`,
      },
      heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "58px",
        mb: "20px",
      },
    },
    dataGrid: {
      wrapper: {
        width: "100%",
        height: "850px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: "32px",
        mb: "16px",
      },
      secondaryWrapper: {
        width: "100%",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: "12px",
      },
      leadsNew: {
        ...tagContainerStyle,
        background: `${Colors.blue[1800]}`,
        color: `${Colors.blue[1700]}`,
      },
      leadsInProgress: {
        ...tagContainerStyle,
        background: `${Colors.yellow[100]}`,
        color: `${Colors.green[600]}`,
      },
      leadsQualified: {
        ...tagContainerStyle,
        background: `${Colors.green[700]}`,
        color: `${Colors.gray[800]}`,
      },
      leadsUnqualified: {
        ...tagContainerStyle,
        background: `${Colors.red[200]}`,
        color: `${Colors.red[100]}`,
      },
      leadsWon: {
        ...tagContainerStyle,
        background: `${Colors.green[700]}`,
        color: `${Colors.gray[800]}`,
      },
      leadsLost: {
        ...tagContainerStyle,
        background: `${Colors.red[200]}`,
        color: `${Colors.red[100]}`,
      },
      workflowCategorySales: {
        ...tagContainerStyle,
        background: `${Colors.blue[1800]}`,
        color: `${Colors.blue[1700]}`,
      },
      workflowCategoryMarketing: {
        ...tagContainerStyle,
        background: `${Colors.green[600]}`,
        color: `${Colors.green[500]}`,
      },
      workflowCategoryOnboarding: {
        ...tagContainerStyle,
        background: `${Colors.yellow[100]}`,
        color: `${Colors.green[600]}`,
      },
      workflowCategoryBusinessOperations: {
        ...tagContainerStyle,
        background: `${Colors.yellow[200]}`,
        color: `${Colors.green[600]}`,
      },
      workflowCategoryFinance: {
        ...tagContainerStyle,
        background: `${Colors.green[200]}`,
        color: `${Colors.green[100]}`,
      },
      workflowCategoryClientEngagement: {
        ...tagContainerStyle,
        background: `${Colors.blue[1500]}`,
        color: "white",
      },
      workflowCategoryDefault: {
        ...tagContainerStyle,
        background: `${Colors.gray[1800]}`,
        color: `${Colors.gray[1700]}`,
      },
      tagActive: {
        ...tagContainerStyle,
        background: `${Colors.green[700]}`,
        color: `${Colors.gray[800]}`,
        textTransform: "none",
      },
      tagInactive: {
        ...tagContainerStyle,
        background: `${Colors.gray[1800]}`,
        color: `${Colors.gray[2000]}`,
        textTransform: "none",
      },
      tagDraft: {
        ...tagContainerStyle,
        background: `${Colors.gray[1800]}`,
        color: `${Colors.gray[2000]}`,
      },
      tagInProgress: {
        ...tagContainerStyle,
        background: `${Colors.yellow[100]}`,
        color: `${Colors.green[600]}`,
      },
      tagCompleted: {
        ...tagContainerStyle,
        background: `${Colors.green[700]}`,
        color: `${Colors.gray[800]}`,
      },
      tagNotStarted: {
        ...tagContainerStyle,
        background: `${Colors.blue[1900]}`,
        color: `${Colors.blue[1800]}`,
        textTransform: "none",
      },
    },
    modal: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      width: "400px",
      height: "auto",
      background: `${Colors.gray[100]}`,
      borderRadius: 3,
      padding: "16px 24px 24px",
      boxShadow:
        "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
    },
    profile: {
      width: "100%",
      overflowY: "scroll",
      position: "absolute",
      top: { xs: "68px", md: "0px" },
      right: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    profileContent: {
      width: "100%",
      height: "100%",
      display: "flex",
      padding: "10px 20px 27px 24px",
      flexDirection: "column",
    },
    timelineCard: {
      client: {
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        padding: "5px",
        background: `${Colors.gray[200]}`,
        border: "1px solid rgba(47, 128, 237, 0.08)",
        boxShadow: "none",
        borderRadius: "8px",
        mr: "0.5rem",
      },
      admin: {
        height: "56px",
        padding: "12px",
        background: "white",
        border: "1px solid rgba(47, 128, 237, 0.08)",
        boxShadow: "none",
        borderRadius: "8px",
        mr: "1rem",
      },
      clientInput: {
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        padding: "5px",
        background: `${Colors.gray[200]}`,
        border: "1px solid rgba(47, 128, 237, 0.08)",
        boxShadow: "none",
        borderRadius: "8px",
        mr: "0.5rem",
      },
    },
  },
  links: {
    noStyle: {
      textDecoration: "none",
    },
    signIn: {
      textDecoration: "underline",
      color: Colors.blue[1200],
    },
    forgotPassword: {
      textDecoration: "none",
      color: Colors.blue[1200],
    },
    backToSignIn: {
      textDecoration: "none",
      color: Colors.blue[1200],
    },
  },
  buttons: {
    normal: {
      bgcolor: Colors.blue[1200],
      textTransform: "none",
      fontWeight: 700,
      borderRadius: "4px",
      mt: 2,
      mb: 2,
    },
    large: {
      mt: 1,
      mb: 3,
      bgcolor: Colors.blue[1200],
      textTransform: "none",
      fontWeight: 700,
      borderRadius: "4px",
      padding: "12px 24px",
      width: "100%",
    },
    warningNormal: {
      bgcolor: Colors.red[200],
      textTransform: "none",
      fontWeight: 700,
      borderRadius: "4px",
    },
    warningLarge: {
      mt: 1,
      mb: 3,
      bgcolor: Colors.red[200],
      textTransform: "none",
      fontWeight: 700,
      borderRadius: "4px",
      padding: "12px 24px",
      width: "100%",
    },
  },
  inputs: {
    signIn: {
      mb: 1,
      width: "inherit",
    },
  },
  headings: {
    signIn: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: "140%",
      color: `${Colors.oxford[1100]}`,
      mb: 1,
    },
    sidebar: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "140%",
      color: `${Colors.gray[900]}`,
      textTransform: "none",
    },
    dataGrid: {
      width: "auto",
      height: "22px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "140%,",
      color: `${Colors.blue[1300]}`,
    },
    profile: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "140%",
      textTransform: "none",
      color: `${Colors.gray[900]}`,
      mb: "16px",
      mt: "26px",
    },
  },
  body: {
    signIn: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "140%",
      color: `${Colors.oxford[1100]}`,
      mb: 3,
    },
    testimonial: {
      title: {
        fontFamily: "Inter",
        fontWeight: "600",
        fontStyle: "normal",
        fontSize: "1.1rem",
        lineHeight: "34px",
        color: `${Colors.gray[100]}`,
        letterSpacing: "-0.02em",
      },
      description: {
        fontFamily: "Inter",
        fontWeight: "500",
        fontStyle: "normal",
        fontSize: "1rem",
        color: `${Colors.gray[100]}`,
      },
      subDescription: {
        fontFamily: "Inter",
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "0.9rem",
        color: `${Colors.gray[100]}`,
      },
    },
    modalDescription: {
      width: "100%",
      height: "44px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "140%",
      color: `${Colors.blue[1300]}`,
      marginBottom: "8px",
    },
    timeline: {
      cardBody: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        color: `${Colors.oxford[1100]}`,
      },
      cardTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        color: `${Colors.oxford[1100]}`,
      },
    },
  },
  tags: {
    workflowCategorySales: {
      ...tagBodyStyle,
      background: `${Colors.blue[1800]}`,
    },
    workflowCategoryMarketing: {
      ...tagBodyStyle,
      background: `${Colors.green[600]}`,
    },
    workflowCategoryOnboarding: {
      ...tagBodyStyle,
      background: `${Colors.yellow[100]}`,
    },
    workflowCategoryBusinessOperations: {
      ...tagBodyStyle,
      background: `${Colors.yellow[200]}`,
    },
    workflowCategoryFinance: {
      ...tagBodyStyle,
      background: `${Colors.green[200]}`,
    },
    workflowCategoryClientEngagement: {
      ...tagBodyStyle,
      background: `${Colors.blue[1500]}`,
    },
    workflowCategoryDefault: {
      ...tagBodyStyle,
      background: `${Colors.gray[1800]}`,
    },
    tagActive: {
      ...tagBodyStyle,
      background: `${Colors.gray[800]}`,
    },
    leadsNew: {
      ...tagBodyStyle,
      background: `${Colors.blue[1800]}`,
    },
    leadsInProgress: {
      ...tagBodyStyle,
      background: `${Colors.yellow[100]}`,
    },
    leadsQualified: {
      ...tagBodyStyle,
      background: `${Colors.green[700]}`,
    },
    leadsUnqualified: {
      ...tagBodyStyle,
      background: `${Colors.red[200]}`,
    },
    leadsWon: {
      ...tagBodyStyle,
      background: `${Colors.green[700]}`,
    },
    leadsLost: {
      ...tagBodyStyle,
      background: `${Colors.red[200]}`,
    },
    tagInactive: {
      ...tagBodyStyle,
      background: `${Colors.gray[1800]}`,
    },
    tagDraft: {
      ...tagBodyStyle,
      background: `${Colors.gray[2000]}`,
    },
    tagInProgress: {
      ...tagBodyStyle,
      background: `${Colors.green[600]}`,
    },
    tagCompleted: {
      ...tagBodyStyle,
      background: `${Colors.gray[800]}`,
    },
    tagNotStarted: {
      ...tagBodyStyle,
      background: `${Colors.blue[1800]}`,
    },
  },
};
