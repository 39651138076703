import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  PartnerTrackingLog,
  PartnerTrackingLogRequest,
  Partner,
} from "./models";

export const partnersApi = createApi({
  baseQuery,
  reducerPath: "partnersApi",
  tagTypes: ["Partners", "PartnerTrackingLog", "PartnerTrackingLogRequest"],
  endpoints: (builder) => ({
    addPartnerTrackingLog: builder.mutation<
      PartnerTrackingLog,
      PartnerTrackingLogRequest
    >({
      query: (body) => ({
        url: `partners/partner-tracking-log`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Partners"],
    }),
    getPartners: builder.query<Partner[], void>({
      query: () => `partners`,
      providesTags: ["Partners"],
    }),
  }),
});

export const { useAddPartnerTrackingLogMutation, useGetPartnersQuery } =
  partnersApi;
