import { WorkoutProgramItem } from "../programs/models";

export interface WorkoutSession {
  id: number;
  dayNumber: number;
  weekNumber: number;
  title: string;
  description: string;
  workoutStatus: string;
  workoutProgramId: number;
  dateTimeCompleted: string;
  previousWorkoutNotes: string;
}

export interface WorkoutResponse {
  currentWorkout: WorkoutSession;
  remainingWorkouts: WorkoutSession[];
  completedWorkouts: WorkoutSession[];
  allWorkouts: WorkoutSession[];
  curentWorkoutProgram: WorkoutProgramItem;
}

export interface WorkoutItemLog {
  id: number;
  completedReps: string;
  completedPartialReps: string;
  completedIntensity: string;
  completedWeight: string;
  completedRepsLeftSide: string;
  completedRepsRightSide: string;
  completedPartialRepsLeftSide: string;
  completedPartialRepsRightSide: string;
  completedIntensityLeftSide: string;
  completedIntensityRightSide: string;
  completedWeightLeftSide: string;
  completedWeightRightSide: string;
  notes: string;
  setOrder: number | null;
  actualRestTime: string;
  workoutItemId: number;
  completedDistance: string;
  completedTime: string;
  completedSpeed: string;
}

export interface WorkoutItem {
  id: number;
  order: number;
  prescribedReps: string;
  prescribedSets: string;
  prescribedWeight: string;
  prescribedIntensity: string;
  completed: boolean;
  notes: string;
  restTime: string;
  workoutId: number;
  exerciseId: number;
  exerciseName: string;
}

export interface WorkoutListItem {
  Order: number;
  PrescribedReps: string;
  PrescribedSets: string;
  PrescribedWeight: string;
  PrescribedIntensity: string;
  Completed: boolean;
  Notes: string;
  RestTime: string;
  WorkoutId: number;
  ExerciseId: number;
  ExerciseName: string;
}

export interface WorkoutItems {
  workoutItems: WorkoutItem[] | null;
}

export interface Exercise {
  id: number;
  name: string;
  description: string;
  muscleGroups: string;
}

export interface WorkoutItemSchemeBreakdown {
  prescribedReps: string;
  prescribedSets: string;
  prescribedWeight: string;
  prescribedIntensity: string;
  actualIntensity: string;
  order: number;
  completed: boolean;
  restTime: string;
  actualWeight: string;
  workoutItemId: number;
  workoutItemLogs: WorkoutItemLog[];
  workoutItemType: WorkoutItemType;
  workoutItemWeightUnit: WorkoutItemWeightUnit;
}

export interface CombinedData {
  exerciseId: number;
  exerciseName: string;
  order: number;
  supersetGroup: string;
  workoutItemId: number;
  workoutId: number;
  workoutProgramId: number;
  workoutItemType: WorkoutItemType;
  workoutLoadStatus: WorkoutLoadStatus;
  exerciseType: string;
  totalSets: number;
  videoUrl: string;
  notes: string;
  previousWeeksData: string[];
  previousWeeksNotes: string[];
  workoutItemWeightUnit: WorkoutItemWeightUnit;
  workoutItemSchemeBreakdowns: WorkoutItemSchemeBreakdown[];
  workoutItemLogs: WorkoutItemLog[];
}

export interface CombinedDataParams {
  userId: number | null;
  workoutId: number | null;
}

export enum WorkoutProgramStatus {
  UNDEFINED = "Undefined",
  DRAFT = "Draft",
  NOT_STARTED = "NotStarted",
  IN_PROGRESS = "InProgress",
  COMPLETED = "Completed",
  SKIPPED = "Skipped",
}

export const WorkoutProgramStatusDictionary: { [key: string]: string } = {
  [WorkoutProgramStatus.DRAFT]: "Draft",
  [WorkoutProgramStatus.NOT_STARTED]: "Not Started",
  [WorkoutProgramStatus.IN_PROGRESS]: "In Progress",
  [WorkoutProgramStatus.COMPLETED]: "Completed",
};

export enum WorkoutItemType {
  UNDEFINED = "Undefined",
  RPE = "RPE",
  PERCENTAGE = "Percentage",
  LOAD = "Load",
  BODYWEIGHT = "Bodyweight",
  RIR = "RIR",
}

export enum WorkoutItemWeightUnit {
  UNDEFINED = "Undefined",
  KG = "Kg",
  LBS = "Lbs",
}

export interface FeedbackParams {
  description?: string;
  rating?: number;
  workoutProgramId: number;
  workoutId: number;
}

export enum WorkoutLoadStatus {
  Undefined = "Undefined",
  Increasing = "Increasing",
  Decreasing = "Decreasing",
  Same = "Same",
}
