import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Typography,
  Divider,
  Icon,
  Snackbar,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useParams, useNavigate } from "react-router-dom";
import { useGetUserProfileItemQuery, useLogoutMutation } from "shared/api";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers";
import { Device } from "@capacitor/device";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SafeAreaBox } from "components/SafeAreaBox/SafeAreaBox";
import { HealthPermissionsDialog } from "pages/Dashboard/AthleteDashboard/HealthPermissionsDialog";
import SahhaService from "../../../SahhaService";
import { SuccessfulUpdateModal } from "./ui/modals/SuccessfulUpdateModal";
import { UserProfileSkeleton } from "./ui/skeletons/UserProfileSkeleton";
import { ProfileForm } from "./ui/ProfileForm";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AthleteUserProfile: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [showPermissionsMessage, setShowPermissionsMessage] = useState(false);
  const [showHealthPermissions, setShowHealthPermissions] = useState(false);
  const [showProfileDialog, setShowProfileDialog] = useState(false);

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    setIsMobileApp(device.platform === "android" || device.platform === "ios");
  };

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const toggleSuccessfulUpdateModal = () => {
    setSuccessfulUpdateModal((prev) => !prev);
  };

  const onLogout = async () => {
    try {
      await logout().unwrap();
      navigate(Routes.signIn.url);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleOpenAppSettings = async () => {
    try {
      if (isMobileApp) {
        await SahhaService.openAppSettings();
      }
    } catch (error) {
      console.error("Error opening app settings:", error);
    }
  };

  const handleOpenHealthPermissions = () => {
    setShowHealthPermissions(true);
  };

  useEffect(() => {
    checkDeviceIsMobileApp();
  }, []);

  if (isLoadingCurrentUserData || !currentUserData) {
    return <UserProfileSkeleton />;
  }

  return (
    <Box sx={{ height: "100vh", backgroundColor: "white" }}>
      <SafeAreaBox sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            <SuccessfulUpdateModal
              successfulUpdateModal={successfulUpdateModal}
              toggleModal={toggleSuccessfulUpdateModal}
            />

            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                p: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  color: Colors.gray[900],
                }}
              >
                Settings
              </Typography>
            </Box>

            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem
                button
                onClick={() => setShowProfileDialog(true)}
                sx={{
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <ListItemText primary="Profile information" />
                <ChevronRightIcon color="action" />
              </ListItem>
              {isMobileApp && (
                <>
                  <ListItem
                    button
                    onClick={handleOpenAppSettings}
                    sx={{
                      borderBottom: `1px solid ${Colors.gray[200]}`,
                    }}
                  >
                    <ListItemText primary="App settings" />
                    <ChevronRightIcon color="action" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={handleOpenHealthPermissions}
                    sx={{
                      borderBottom: `1px solid ${Colors.gray[200]}`,
                    }}
                  >
                    <ListItemText primary="Health permissions" />
                    <ChevronRightIcon color="action" />
                  </ListItem>
                </>
              )}
            </List>

            <Box sx={{ p: 2, mt: "auto" }}>
              <Button
                onClick={onLogout}
                sx={{
                  color: "#FF3B30",
                  textTransform: "none",
                  fontSize: "17px",
                  fontWeight: 400,
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                Logout
              </Button>
            </Box>

            <Snackbar
              open={showPermissionsMessage}
              autoHideDuration={3000}
              onClose={() => setShowPermissionsMessage(false)}
              message="Health data permissions enabled"
            />

            <HealthPermissionsDialog
              isMobileApp={isMobileApp}
              open={showHealthPermissions}
              onClose={() => setShowHealthPermissions(false)}
            />

            <Dialog
              fullScreen
              open={showProfileDialog}
              onClose={() => setShowProfileDialog(false)}
              TransitionComponent={Transition}
            >
              <AppBar
                sx={{
                  position: "relative",
                  pt: 6,
                  background: "white",
                  boxShadow: "none",
                  borderBottom: `1px solid ${Colors.gray[200]}`,
                }}
              >
                <Toolbar>
                  <Typography
                    sx={{
                      flex: 1,
                      color: Colors.gray[900],
                      fontSize: "17px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Profile
                  </Typography>
                  <IconButton
                    edge="end"
                    onClick={() => setShowProfileDialog(false)}
                    aria-label="close"
                    sx={{ color: Colors.gray[900] }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Box>
                <ProfileForm
                  currentUserData={currentUserData}
                  onSuccess={() => {
                    setShowProfileDialog(false);
                    toggleSuccessfulUpdateModal();
                  }}
                />
              </Box>
            </Dialog>
          </Box>
        </Box>
      </SafeAreaBox>
    </Box>
  );
};

export { AthleteUserProfile };
