import { FC, useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Grid,
  Tab,
  Typography,
  styled,
  Card,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useGetUserProfileItemQuery } from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { GridStatusItem } from "components/Grid/GridStatusItem";
import { useTypedSelector } from "shared/stores";

// Import Tabs components
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Import other components used in tabs
import { UserProfileProgramsContainer } from "pages/Programs/AdminPrograms/ui/UserProfileProgramsContainer";
import { TrainingInsightsContent } from "pages/TrainingInsights/TrainingInsightsContent";
import { Routes } from "shared/routers";
import { UserProfileTimeline } from "pages/Timeline";
import { UserProfileNotes } from "pages/UserNotes/UserProfileNotes";
import { ClientFeedbackSummary } from "pages/Users/ClientFeedbackSummary";
import { UserNotesTable } from "pages/UserNotes";
import { UserProfileGoals } from "pages/UserNotes/UserProfileGoals";
import { UserProfileNutritionPlans } from "pages/Nutrition/UserProfileNutritionPlans";
import { UserPersonalRecordsModal } from "pages/Progress/UserPersonalRecordsModal";

import { UserProfilePersonalBests } from "pages/Progress/UserProfilePersonalBests";
import { UpdateUserForm } from "./ui/UpdateUserForm";
import { UserProfileBilling } from "./UserProfileBilling";
import { SuccessfulUpdateModal } from "./ui/modals/SuccessfulUpdateModal";
import { UserProfileSkeleton } from "./ui/skeletons/UserProfileSkeleton";
import { UserProfileHealthMetrics } from "./UserProfileHealthMetrics";
import { UserProfileTabValues } from "./constants/constants";

interface UserProfileProps {
  closeSidebar: () => void;
}

const BannerCard = styled(Card)(({ theme }) => ({
  color: "black",
  marginBottom: theme.spacing(2),
  boxShadow: "none",
}));
const BannerItem = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));

const UserProfile: FC<UserProfileProps> = ({ closeSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const params = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(params.tabId || "1");

  useEffect(() => {
    if (params.tabId !== tabValue) {
      setTabValue(params.tabId || "1");
    }
  }, [params.tabId]);

  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (currentUserData?.id)
      navigate(`${Routes.profile.url}/${currentUserData.id}/${newValue}`);
  };

  const [successfulUpdateModal, setSuccessfulUpdateModal] = useState(false);
  const toggleSuccessfulUpdateModal = () => {
    setSuccessfulUpdateModal((prev) => !prev);
  };

  const [showCompare, setShowCompare] = useState(false);
  const [isPersonalBestsOpen, setIsPersonalBestsOpen] = useState(false);

  // Wait for currentUserData to be loaded before rendering components that depend on it
  if (isLoadingCurrentUserData || !currentUserData) {
    return <UserProfileSkeleton />;
  }

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        height: "100vh",
        overflowY: "auto",
        position: "absolute",
        right: "0px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <SuccessfulUpdateModal
        successfulUpdateModal={successfulUpdateModal}
        toggleModal={toggleSuccessfulUpdateModal}
      />
      <UserPersonalRecordsModal
        open={isPersonalBestsOpen}
        onClose={() => setIsPersonalBestsOpen(false)}
        userId={currentUserData.id}
      />
      <Box
        sx={{
          width: "100%",
          minHeight: "100%",
          display: "flex",
          padding: "60px 20px 27px 24px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <IdenticonAvatar
              sizeValue={40}
              seedValue={currentUserData.id.toString()}
              profileImageUrl={currentUserData.profileImageUrl || ""}
              allowUpload
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              textTransform: "none",
              color: `${Colors.gray[900]}`,
              mb: "16px",
              mt: "26px",
              pl: 1,
            }}
          >
            {currentUserData.firstName} {currentUserData.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "10%",
            width: "100px",
            mb: "16px",
          }}
        >
          <GridStatusItem status={currentUserData.userStatus} />
        </Box>
        {/* Updated TabContext */}
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="Tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                maxWidth: "100%",
                ".MuiTabs-scroller": {
                  overflowX: "auto !important",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                },
              }}
            >
              <Tab
                label="Overview"
                value={UserProfileTabValues.Overview}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Programs"
                value={UserProfileTabValues.Programs}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Health Metrics"
                value={UserProfileTabValues.HealthMetrics}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Training Insights"
                value={UserProfileTabValues.TrainingInsights}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Nutrition"
                value={UserProfileTabValues.Nutrition}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Billing"
                value={UserProfileTabValues.Billing}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Settings"
                value={UserProfileTabValues.Settings}
                sx={{ textTransform: "none" }}
              />
            </TabList>
          </Box>
          <Grid>
            <TabPanel
              value={UserProfileTabValues.Overview}
              sx={{ p: matches ? 3 : 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Grid spacing={2} container>
                    <Grid item xs={12} md={4}>
                      <BannerCard>
                        <CardContent>
                          <BannerItem variant="body2">
                            Current program
                          </BannerItem>
                          <NavLink
                            to={`${Routes.modernWorkoutProgramBuilder.url}/${currentUserData.currentWorkoutProgramId}`}
                          >
                            <Button
                              variant="text"
                              color="inherit"
                              sx={{ textTransform: "none", p: 0 }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  color: `${Colors.blue[800]}`,
                                }}
                              >
                                {currentUserData.currentWorkoutProgramName}
                              </Typography>
                            </Button>
                          </NavLink>
                        </CardContent>
                      </BannerCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <BannerCard>
                        <CardContent>
                          <BannerItem variant="body2">
                            Sessions completed last 7 days
                          </BannerItem>
                          <Typography variant="body1" color="inherit">
                            {currentUserData.workoutsCompletedLast7Days}
                          </Typography>
                        </CardContent>
                      </BannerCard>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <BannerCard>
                        <CardContent>
                          <BannerItem variant="body2">
                            Sessions completed last 30 days
                          </BannerItem>
                          <Typography variant="body1" color="inherit">
                            {currentUserData.workoutsCompletedLast30Days}
                          </Typography>
                        </CardContent>
                      </BannerCard>
                    </Grid>
                  </Grid>
                  <UserProfileTimeline userId={currentUserData.id} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ClientFeedbackSummary selectedUserId={currentUserData.id} />
                  <UserProfilePersonalBests
                    preselectedUserId={currentUserData.id}
                  />
                  <UserProfileGoals preselectedUserId={currentUserData.id} />
                  <UserProfileNotes
                    preselectedUserId={currentUserData.id}
                    noteType="General"
                  />
                  <UserProfileNotes
                    preselectedUserId={currentUserData.id}
                    noteType="Injury"
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.Programs}
              sx={{ p: matches ? 3 : 1 }}
            >
              <UserProfileProgramsContainer userId={currentUserData.id} />
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.TreatmentNotes}
              sx={{ p: matches ? 3 : 1 }}
            >
              <UserNotesTable preselectedUserId={currentUserData!.id} />
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.HealthMetrics}
              sx={{ p: matches ? 3 : 1 }}
            >
              <UserProfileHealthMetrics userId={currentUserData.id} />
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.TrainingInsights}
              sx={{ p: matches ? 3 : 1 }}
            >
              <Box>
                <Button
                  variant="text"
                  onClick={() => setShowCompare(!showCompare)}
                  sx={{ mb: 2, textTransform: "none" }}
                >
                  {showCompare
                    ? "Switch to Single Exercise View"
                    : "Compare with Another Exercise"}
                </Button>
                <Grid container spacing={2}>
                  <Grid item xs={showCompare ? 6 : 12}>
                    <TrainingInsightsContent
                      preselectedUserId={currentUserData.id}
                    />
                  </Grid>
                  {showCompare && (
                    <Grid item xs={6}>
                      <TrainingInsightsContent
                        preselectedUserId={currentUserData.id}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.Nutrition}
              sx={{ p: matches ? 3 : 1 }}
            >
              <UserProfileNutritionPlans userId={currentUserData.id} />
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.Billing}
              sx={{ p: matches ? 3 : 1 }}
            >
              <UserProfileBilling
                currentUserData={currentUserData}
                toggleSuccessfulUpdateModal={toggleSuccessfulUpdateModal}
              />
            </TabPanel>
            <TabPanel
              value={UserProfileTabValues.Settings}
              sx={{ p: matches ? 3 : 1 }}
            >
              <Grid item xs={12} md={12} lg={12}>
                <UpdateUserForm
                  currentUserData={currentUserData}
                  toggleSuccessfulUpdateModal={toggleSuccessfulUpdateModal}
                />
              </Grid>
            </TabPanel>
          </Grid>
        </TabContext>
      </Box>
    </Box>
  );
};

export { UserProfile };
