import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar/IdenticonAvatar";
import { Routes } from "shared/routers";
import { Link } from "react-router-dom";

interface EmployeeDashboardItemProps {
  userId: number;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  score: number;
}

const EmployeeDashboardItem: FC<EmployeeDashboardItemProps> = ({
  userId,
  firstName,
  lastName,
  profileImageUrl,
  score,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Link
      to={`${Routes.profile.url}/${userId}`}
      style={{ textDecoration: "none" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItem: "center",
          justifyContent: "space-between",
          mb: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <IdenticonAvatar
            seedValue={userId.toString()}
            profileImageUrl={profileImageUrl}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItem: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                mt: "8px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
              }}
            >
              {firstName} {lastName}
            </Typography>
            {score !== null && (
              <Typography
                sx={{
                  mt: "4px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {score}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export { EmployeeDashboardItem };
