import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { useTypedSelector } from "shared/stores";
import {
  useGetChallengesQuery,
  useGetChallengesCountQuery,
  CreateChallengeParams,
  useCreateChallengeMutation,
} from "shared/api";
import { getGraphicSvg } from "shared/themes";
import { ProgramsSkeleton } from "pages/Programs/AdminPrograms/ui/ProgramsSkeleton";
import { ChallengeCard } from "./ChallengeCard";
import { AddChallengeModal } from "./AddChallengeModal";

interface ChallengesProps {
  openSidebar: () => void;
}

const Challenges: FC<ChallengesProps> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [count, setCount] = useState(10);

  const [page, setPage] = useState(1);

  const { data: challengesArrayLength } = useGetChallengesCountQuery({
    countOnly: true,
    active: true,
  });

  const {
    data: challenges,
    isLoading,
    refetch: refetchChallenges,
  } = useGetChallengesQuery({
    count,
    page,
    countOnly: false,
    active: true,
  });

  const [rowCountState, setRowCountState] = useState(
    challengesArrayLength || 0
  );

  const [openModal, setOpenModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [createChallenge, { isLoading: isCreatingChallenge }] =
    useCreateChallengeMutation();

  const handleCreateChallengeClick = async (payload: CreateChallengeParams) => {
    try {
      await createChallenge(payload).unwrap();
      setSuccessMessage("Challenge created successfully");
      refetchChallenges();
    } catch (error: any) {
      setErrorMessage(error?.data?.message || "An error occurred");
    } finally {
      setOpenModal(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2500);
    }
  };

  const activeChallenges = challenges?.filter(
    (challenge) => challenge.challengeStatus.toString() === "Active"
  );

  const scheduledChallenges = challenges?.filter(
    (challenge) => challenge.challengeStatus.toString() === "Scheduled"
  );

  const completedChallenges = challenges?.filter(
    (challenge) => challenge.challengeStatus.toString() === "Completed"
  );

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>Challenges</Typography>
        <div>
          <PrimaryButton
            value="Add challenge"
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(!openModal)}
            icon="add_icon"
          />
        </div>
      </Box>
      {isLoading && <ProgramsSkeleton />}
      {!isLoading && challenges && challenges?.length > 0 && (
        <>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 2, mb: 2 }}
          >
            Active
          </Typography>
          <Grid container spacing={2}>
            {activeChallenges?.map((challenge) => (
              <Grid item xs={12} sm={6} md={4} key={challenge.id}>
                <ChallengeCard challenge={challenge} />
              </Grid>
            ))}
            {activeChallenges?.length === 0 && (
              <Box>
                <Box
                  component="img"
                  src={getGraphicSvg("thinking")}
                  alt="Thinking"
                  sx={{ height: "110px", mb: 2 }}
                />
                <Typography variant="body1" color="textSecondary">
                  There are no active challenges.
                </Typography>
              </Box>
            )}
          </Grid>
          <Divider sx={{ mt: 4, mb: 4 }} />
          {scheduledChallenges && scheduledChallenges?.length > 0 && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 2, mb: 2 }}
              >
                Scheduled
              </Typography>
              <Grid container spacing={2}>
                {scheduledChallenges?.map((challenge) => (
                  <Grid item xs={12} sm={6} md={4} key={challenge.id}>
                    <ChallengeCard challenge={challenge} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Divider sx={{ mt: 4, mb: 4 }} />
          {completedChallenges && completedChallenges?.length > 0 && (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 2, mb: 2 }}
              >
                Completed
              </Typography>
              <Grid container spacing={2}>
                {completedChallenges?.map((challenge) => (
                  <Grid item xs={12} sm={6} md={4} key={challenge.id}>
                    <ChallengeCard challenge={challenge} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
      {!isLoading && challenges && challenges?.length === 0 && (
        <Box>
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body1" color="textSecondary">
            There are no challenges. Create a new challenge to get started.
          </Typography>
        </Box>
      )}
      <AddChallengeModal
        isOpenModal={openModal}
        handleCloseModal={() => setOpenModal(!openModal)}
        isLoading={isCreatingChallenge}
        onSubmit={(payload: CreateChallengeParams) => {
          handleCreateChallengeClick(payload);
        }}
      />
    </Box>
  );
};

export { Challenges };
