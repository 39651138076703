import { FC, useState, useCallback } from "react";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { AnyType } from "shared/helpers";
import {
  useGetUserProfileItemQuery,
  UserProfileItemResponse,
  useUpdateUserProfileMutation,
  useGetUsersQuery,
} from "shared/api";
import { Colors } from "shared/themes";
import { BillingFrequency } from "shared/api/user/models";
import dayjs from "dayjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface UserProfileBillingProps {
  currentUserData?: UserProfileItemResponse;
  toggleSuccessfulUpdateModal: () => void;
  isAthleteProfileView?: boolean;
}

interface SubmitValues {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  myFitnessPalUsername?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  userRole?: string;
  userStatus?: string;
  userCategory?: string;
  assignedFitnessProfessionalId?: number;
  userLiftingProfileType?: string;
  swapExercisesEnabled?: boolean;
  billingFrequency?: string;
  billingStartDate?: string;
  billingEndDate?: string;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  emergencyContactRelationship?: string;
  emergencyContactEmail?: string;
}

interface FieldChange {
  field: string;
  oldValue: any;
  newValue: any;
}

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Min 2").required("This field is required"),
  lastName: Yup.string().min(2, "Min 2").required("This field is required"),
  billingStartDate: Yup.date().nullable(),
  billingEndDate: Yup.date()
    .nullable()
    .test(
      "is-after-start",
      "End date must be after start date",
      function (endDate) {
        const { billingStartDate } = this.parent;
        if (!billingStartDate || !endDate) return true; // If either date is not set, validation passes
        return dayjs(endDate).isAfter(dayjs(billingStartDate));
      }
    ),
});

const UserProfileBilling: FC<UserProfileBillingProps> = ({
  currentUserData,
  toggleSuccessfulUpdateModal,
  isAthleteProfileView = false,
}) => {
  const { t } = useTranslation();
  const loggedInUserIsAdminOrCoach = useTypedSelector(
    userSelectors.isAdminOrCoach
  );
  const loggedInUserIsAdmin = useTypedSelector(userSelectors.isAdmin);

  const [errorMessage, setErrorMessage] = useState("");
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const { data: updatedUser, refetch } = useGetUserProfileItemQuery(
    currentUserData!.id
  );

  const {
    data: fitnessProfessionals,
    isLoading: isLoadingFitnessProfessionals,
  } = useGetUsersQuery({
    count: 0,
    page: 0,
    userRole: "admin",
    orderByName: true,
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<FieldChange[]>([]);

  const onSubmit = async (params: SubmitValues) => {
    setErrorMessage("");
    try {
      await updateUserProfile({
        ...params,
        swapExercisesEnabled: `${params.swapExercisesEnabled}`,
        billingFrequency:
          BillingFrequency[
            params.billingFrequency as keyof typeof BillingFrequency
          ],
      }).unwrap();
      toggleSuccessfulUpdateModal();
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      await refetch();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik<SubmitValues>({
    initialValues: {
      id: currentUserData?.id,
      firstName: currentUserData?.firstName,
      lastName: currentUserData?.lastName,
      phoneNumber: currentUserData?.phoneNumber,
      email: currentUserData?.email,
      userStatus: currentUserData?.userStatus,
      userCategory: currentUserData?.userCategory,
      userRole: currentUserData?.userRole,
      dateOfBirth: currentUserData?.dateOfBirth
        ? dayjs(currentUserData.dateOfBirth).format("YYYY-MM-DD")
        : "",
      assignedFitnessProfessionalId:
        currentUserData?.assignedFitnessProfessionalId,
      userLiftingProfileType:
        currentUserData?.userLiftingProfileType?.liftingProfileType,
      myFitnessPalUsername: currentUserData?.myFitnessPalUsername,
      swapExercisesEnabled: currentUserData?.swapExercisesEnabled,
      billingFrequency: currentUserData?.billingFrequency || "Undefined",
      billingStartDate: currentUserData?.billingStartDate,
      billingEndDate: currentUserData?.billingEndDate,
      emergencyContactName: currentUserData?.emergencyContactName,
      emergencyContactNumber: currentUserData?.emergencyContactNumber,
      emergencyContactRelationship:
        currentUserData?.emergencyContactRelationship,
      emergencyContactEmail: currentUserData?.emergencyContactEmail,
    },
    enableReinitialize: true,
    validationSchema: UserProfileSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const getDisplayValue = (field: string, value: any): string => {
    switch (field) {
      case "swapExercisesEnabled":
        return value ? "Yes" : "No";
      case "billingFrequency":
        return value === "Undefined" ? "None" : value;
      case "billingStartDate":
      case "billingEndDate":
        return value ? dayjs(value).format("YYYY-MM-DD") : "None";
      case "assignedFitnessProfessionalId":
        // eslint-disable-next-line no-case-declarations
        const coach = fitnessProfessionals?.find((fp) => fp.id === value);
        return coach ? `${coach.firstName} ${coach.lastName}` : "None";
      case "userLiftingProfileType":
        return value === "Undefined" ? "None" : value;
      default:
        return value?.toString() || "None";
    }
  };

  // Helper function to convert value to boolean
  const toBooleanValue = (value: string | boolean | undefined): boolean => {
    if (typeof value === "boolean") return value;
    if (typeof value === "string") return value.toLowerCase() === "true";
    return false;
  };

  const handleSubmitClick = () => {
    const changes: FieldChange[] = [];

    Object.keys(values).forEach((key) => {
      const currentValue = values[key as keyof SubmitValues];
      let originalValue =
        currentUserData?.[key as keyof UserProfileItemResponse];

      // Special handling for swapExercisesEnabled
      if (key === "swapExercisesEnabled") {
        const currentBool = toBooleanValue(currentValue as string);
        const originalBool = toBooleanValue(originalValue as string);
        if (currentBool !== originalBool) {
          changes.push({
            field: key,
            oldValue: originalBool,
            newValue: currentBool,
          });
        }
        return;
      }

      // Special handling for userLiftingProfileType
      if (key === "userLiftingProfileType") {
        const liftingProfileType =
          currentUserData?.userLiftingProfileType?.liftingProfileType;
        originalValue = liftingProfileType;
      }

      if (key === "dateOfBirth") {
        originalValue = dayjs(originalValue?.toString()).format("YYYY-MM-DD");
      }

      if (currentValue !== originalValue) {
        changes.push({
          field: key,
          oldValue: originalValue,
          newValue: currentValue,
        });
      }
    });

    if (changes.length > 0) {
      setPendingChanges(changes);
      setShowConfirmDialog(true);
    }
  };

  const checkBeforeSubmit = useCallback(() => {
    const currentSwapExercises = toBooleanValue(values.swapExercisesEnabled);
    const originalSwapExercises = toBooleanValue(
      currentUserData?.swapExercisesEnabled
    );

    return (
      values.dateOfBirth === currentUserData?.dateOfBirth &&
      values.email === currentUserData?.email &&
      values.firstName === currentUserData?.firstName &&
      values.lastName === currentUserData?.lastName &&
      values.phoneNumber === currentUserData?.phoneNumber &&
      values.userRole === currentUserData?.userRole &&
      values.userStatus === currentUserData?.userStatus &&
      values.userCategory === currentUserData?.userCategory &&
      values.assignedFitnessProfessionalId ===
        currentUserData?.assignedFitnessProfessionalId &&
      values.userLiftingProfileType ===
        currentUserData?.userLiftingProfileType?.liftingProfileType &&
      values.myFitnessPalUsername === currentUserData?.myFitnessPalUsername &&
      currentSwapExercises === originalSwapExercises &&
      values.billingFrequency === currentUserData?.billingFrequency &&
      values.billingStartDate === currentUserData?.billingStartDate &&
      values.billingEndDate === currentUserData?.billingEndDate &&
      values.emergencyContactName === currentUserData?.emergencyContactName &&
      values.emergencyContactNumber ===
        currentUserData?.emergencyContactNumber &&
      values.emergencyContactRelationship ===
        currentUserData?.emergencyContactRelationship &&
      values.emergencyContactEmail === currentUserData?.emergencyContactEmail
    );
  }, [values, currentUserData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Box
          sx={{
            bgcolor: `${Colors.gray[100]}`,
            mt: "20px",
            padding: "20px",
            height: "auto",
            width: "100%",
          }}
        >
          {/* Billing Information Section */}
          {loggedInUserIsAdminOrCoach && (
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: Colors.gray[900],
                  mb: 2,
                }}
              >
                Billing Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Frequency
                  </Typography>
                  <Select
                    fullWidth
                    value={values.billingFrequency || "Undefined"}
                    onBlur={handleBlur("billingFrequency")}
                    onChange={(e) =>
                      setFieldValue("billingFrequency", e.target.value)
                    }
                    size="small"
                  >
                    <MenuItem value="Undefined">None</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Biannually">Biannually</MenuItem>
                    <MenuItem value="Annually">Annually</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Start Date
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="date"
                    value={
                      values.billingStartDate
                        ? dayjs(values.billingStartDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      setFieldValue("billingStartDate", e.target.value);
                    }}
                    error={!!errors.billingStartDate}
                    helperText={errors.billingStartDate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    End Date
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="date"
                    value={
                      values.billingEndDate
                        ? dayjs(values.billingEndDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      setFieldValue("billingEndDate", e.target.value);
                    }}
                    error={!!errors.billingEndDate}
                    helperText={errors.billingEndDate}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Submit Button */}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmitClick}
              disabled={checkBeforeSubmit()}
              color="primary"
              variant="contained"
              sx={{
                minWidth: "120px",
                textTransform: "none",
              }}
            >
              {t("user-profile.update-btn")}
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Review Changes</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            The following changes will be made:
          </Typography>
          <Stack spacing={2.5}>
            {pendingChanges.map((change, index) => {
              const isEmailChange = change.field === "email";
              const fieldName = change.field
                .replace(/([A-Z])/g, " $1")
                .trim()
                .replace(/\b\w/g, (c) => c.toUpperCase());

              return (
                <Box key={index}>
                  <Typography
                    variant="subtitle2"
                    color={Colors.gray[600]}
                    sx={{ fontWeight: 600, mb: 0.5 }}
                  >
                    {fieldName}
                  </Typography>
                  {isEmailChange ? (
                    <Stack spacing={1} sx={{ ml: 2 }}>
                      <Typography variant="body2">
                        Current:{" "}
                        <span style={{ color: Colors.red[200] }}>
                          {getDisplayValue(change.field, change.oldValue)}
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        New:{" "}
                        <span style={{ color: Colors.green[900] }}>
                          {getDisplayValue(change.field, change.newValue)}
                        </span>
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {getDisplayValue(change.field, change.newValue)}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Stack>
          {pendingChanges.some((change) => change.field === "email") && (
            <Box
              sx={{
                mt: 3,
                p: 2,
                bgcolor: "warning.light",
                borderRadius: 1,
                border: 1,
                borderColor: "warning.main",
              }}
            >
              <Typography
                variant="body2"
                color="warning.dark"
                sx={{ fontWeight: 500 }}
              >
                ⚠️ Warning: Changing the email address is a sensitive action
                that will affect the user&apos;s ability to log in.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            onClick={() => setShowConfirmDialog(false)}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowConfirmDialog(false);
              handleSubmit();
            }}
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Confirm Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export { UserProfileBilling };
