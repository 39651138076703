import React, { useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { BrowserRouter, Routes as RoutesDom, Route } from "react-router-dom";
import {
  AdminDashboard,
  AdminWorkplaceWellbeingDashboard,
  AthleteDashboard,
  EmployeeDashboard,
  EmployeeUserProfile,
  WorkoutDetails,
  SignIn,
  FitnessProfessionalSignup,
  FitnessProfessionalSignupEmailVerification,
  ClientSignupSuccess,
  ClientSignupEmailVerification,
  ResetPasswordEmailInput,
  ResetPasswordVerification,
  ResetPasswordSuccess,
  ExerciseView,
  Timeline,
  AdminTimelineView,
  WorkoutProgramBuilderSuccessScreen,
  AthletePrograms,
  AdminPrograms,
  ViewPrograms,
  TrainingInsights,
  AthleteTrainingInsights,
  LiveTraining,
  UserProfile,
  OrganisationManagement,
  OrganisationIntegrationSetupSuccess,
  AthleteUserProfile,
  FitnessProfessionalSignupSuccess,
  Users,
  Employees,
  Layout,
  Workflows,
  IndividualWorkflow,
  LeadsContainer,
  IndividualLead,
  BillingContainer,
  UserNoteView,
  OrganisationUserNotesTable,
  ExerciseLibrary,
  ModernProgramBuilder,
  AthleteProgramWorkouts,
  AthleteWorkouts,
  BookingsContainer,
  AthleteBookingSchedule,
  NutritionPlans,
  NutritionPlanView,
  AthleteNutrition,
  AthleteNutritionView,
  AthleteTimelineSelector,
  AdminLeaderboard,
  AthleteProgress,
  Challenges,
  ChallengeContainer,
} from "pages";
import { useAnalytics, useGetUser } from "shared/hooks";
import { useTypedDispatch, useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import {
  setSidebarState,
  toggleSidebarState,
} from "shared/stores/sidebar/slice";
import {
  ProtectedRoute,
  ProtectedAdminRoute,
  ProtectedAdminOrCoachRoute,
} from "./ProtectedRoute";
import { Routes } from "./Routes";

export const Router: React.FC = () => {
  const { startSession } = useAnalytics();
  useGetUser();
  startSession();

  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const organisationType = useTypedSelector(userSelectors.organisationType);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const dispatch = useTypedDispatch();

  const openSidebar = () => {
    dispatch(setSidebarState(true));
  };

  const closeSidebar = () => {
    dispatch(setSidebarState(false));
  };

  const toggleSidebar = () => {
    dispatch(toggleSidebarState());
  };

  const [createWorkoutUserId, setCreateWorkoutUserId] = useState<number | null>(
    null
  );
  const getUserIdForWorkoutCreation = (userId: number) =>
    setCreateWorkoutUserId(userId);

  const [isMobileApp, setIsMobileApp] = useState(false);

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  useEffect(() => {
    checkDeviceIsMobileApp();
  }, []);

  const renderUserProfile = () => {
    if (isMobileApp && organisationType === "WorkplaceWellbeing") {
      return <AthleteUserProfile />;
    }

    if (isAdminOrCoach && organisationType === "WorkplaceWellbeing") {
      return <EmployeeUserProfile closeSidebar={closeSidebar} />;
    }

    if (isAdminOrCoach) {
      return <UserProfile closeSidebar={closeSidebar} />;
    }

    return <AthleteUserProfile />;
  };

  const renderDashboard = () => {
    if (organisationType === "WorkplaceWellbeing" && isMobileApp) {
      return (
        <Layout
          isOpenSidebar={isOpenSidebar}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
          toggleSidebar={toggleSidebar}
        >
          <EmployeeDashboard />
        </Layout>
      );
    }

    if (isAdminOrCoach) {
      if (organisationType === "WorkplaceWellbeing") {
        return (
          <Layout
            isOpenSidebar={isOpenSidebar}
            closeSidebar={closeSidebar}
            openSidebar={openSidebar}
            toggleSidebar={toggleSidebar}
          >
            <AdminWorkplaceWellbeingDashboard />
          </Layout>
        );
      }
      return (
        <AdminDashboard
          closeSidebar={closeSidebar}
          isOpenSidebar={isOpenSidebar}
          toggleSidebar={toggleSidebar}
          openSidebar={openSidebar}
          getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
        />
      );
    }

    if (organisationType === "WorkplaceWellbeing") {
      return (
        <Layout
          isOpenSidebar={isOpenSidebar}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
          toggleSidebar={toggleSidebar}
        >
          <EmployeeDashboard />
        </Layout>
      );
    }

    return (
      <Layout
        isOpenSidebar={isOpenSidebar}
        closeSidebar={closeSidebar}
        openSidebar={openSidebar}
        toggleSidebar={toggleSidebar}
      >
        <AthleteDashboard />
      </Layout>
    );
  };

  return (
    <BrowserRouter>
      <RoutesDom>
        <Route path={Routes.signIn.url} element={<SignIn />} />
        <Route
          path={Routes.fitnessProfessionalSignup.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.termsOfService.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupEmailVerification.url}
          element={<FitnessProfessionalSignupEmailVerification />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupSuccess.url}
          element={<FitnessProfessionalSignupSuccess />}
        />
        <Route
          path={Routes.clientSignupEmailVerification.url}
          element={<ClientSignupEmailVerification />}
        />
        <Route
          path={Routes.clientSignupSuccess.url}
          element={<ClientSignupSuccess />}
        />
        <Route
          path={Routes.forgotPassword.url}
          element={<ResetPasswordEmailInput />}
        />
        <Route
          path={Routes.forgotPasswordVerification.url}
          element={<ResetPasswordVerification />}
        />
        <Route
          path={Routes.forgotPasswordSuccess.url}
          element={<ResetPasswordSuccess />}
        />
        <Route
          path={Routes.dashboard.url}
          element={<ProtectedRoute>{renderDashboard()}</ProtectedRoute>}
        />
        <Route
          path={Routes.programs.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <AdminPrograms
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                    setCreateWorkoutUserId={setCreateWorkoutUserId}
                  />
                ) : (
                  <AthletePrograms />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.programs.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <ViewPrograms
                    openSidebar={openSidebar}
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                  />
                ) : (
                  <AthleteProgramWorkouts />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteWorkouts />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}/:userId/:completed?`}
          element={
            <ProtectedRoute>
              <WorkoutDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.profile.url}/:userId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {renderUserProfile()}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.clients.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Users openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.employees.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Employees openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.challenges.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Challenges openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.challenges.url}/:id`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ChallengeContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.exerciseLibrary.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseLibrary />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.exercise.url}/:exerciseId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <Timeline />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.athleteTimeline.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteTimelineSelector />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteBookings.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && (
                  <AthleteBookingSchedule closeSidebar={closeSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}/:timelineId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AdminTimelineView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.modernWorkoutProgramBuilder.url}/:workoutProgramId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <ModernProgramBuilder />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workoutProgramBuilderSuccess.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <WorkoutProgramBuilderSuccessScreen openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.organisation.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdmin && (
                  <OrganisationManagement openSidebar={openSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.organisationIntegrationSetupSuccess.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationIntegrationSetupSuccess
                  openSidebar={openSidebar}
                />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Workflows />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}/:workflowId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualWorkflow />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.liveTraining.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <LiveTraining />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.trainingInsights.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <TrainingInsights openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.athleteTrainingInsights.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteTrainingInsights />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <LeadsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualLead />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.billing.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BillingContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.bookings.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BookingsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationUserNotesTable />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}/:userNoteId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <UserNoteView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlans />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlanView />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteNutrition />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteNutritionView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.leaderboard.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <AdminLeaderboard />}
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={Routes.progress.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AthleteProgress />
              </Layout>
            </ProtectedRoute>
          }
        />
      </RoutesDom>
    </BrowserRouter>
  );
};
