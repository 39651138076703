import { Box } from "@mui/system";
import { useState } from "react";
import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/react";
import { Underline } from "@tiptap/extension-underline";
import { Icon, IconButton, Typography, Card, Button } from "@mui/material";
import { Colors } from "shared/themes";

interface WorkoutDetailsSessionNotesProps {
  content?: string;
  previousSession?: string;
  onClose?: () => void;
}

export const WorkoutDetailsSessionNotes = ({
  content,
  previousSession,
  onClose,
}: WorkoutDetailsSessionNotesProps) => {
  const [expanded, setExpanded] = useState(true);

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content,
    editable: false,
  });

  return (
    <Box sx={{ height: "100%" }}>
      <Card
        sx={{
          width: "100%",
          position: "relative",
          borderRadius: 0,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
          pt: 8,
          pb: 3,
          pl: 3,
          pr: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
          <Button
            type="button"
            onClick={onClose}
            sx={{
              minWidth: "auto",
              padding: 0,
              color: Colors.gray[100],
              "&:hover": {
                background: "none",
              },
              textTransform: "none",
            }}
          >
            <Icon
              sx={{
                fontSize: "16px !important",
                width: "16px",
                height: "16px",
              }}
            >
              arrow_back_ios
            </Icon>
            <Typography
              sx={{
                fontSize: "14px",
                ml: 0.5,
                color: Colors.gray[100],
                fontWeight: 500,
              }}
            >
              Return
            </Typography>
          </Button>
        </Box>
        <Typography
          variant="h5"
          sx={{
            color: Colors.gray[100],
            fontWeight: 600,
          }}
        >
          {content
            ? "Coach’s note for this session"
            : "Your last session feedback"}
        </Typography>
      </Card>

      <Box sx={{ p: 2 }}>
        {content && (
          <Box
            sx={{
              "& .ProseMirror": {
                minHeight: "auto",
                padding: "0",
                "& p": {
                  margin: 0,
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "140%",
                  color: `${Colors.oxford[1100]}`,
                },
              },
            }}
          >
            <EditorContent editor={editor} />
          </Box>
        )}
        {previousSession && (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "normal",
              color: `${Colors.oxford[1100]}`,
            }}
            dangerouslySetInnerHTML={{
              __html: previousSession,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
