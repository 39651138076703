/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import { FormContainer } from "components/Form/FormContainer";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { LogoLink } from "components/Form/LogoLink";
import { Routes } from "../../../shared/routers/Routes";

export const ClientSignupSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <Container sx={sharedStyles.containers.signIn.form}>
        <LogoLink />
        <Typography sx={sharedStyles.headings.signIn}>
          {t("signup.client.successTitle")}
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          Your account has been successfully created.
        </Typography>
        <Typography sx={sharedStyles.body.signIn}>
          Download the FitFocus app to login and get started.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Link to="https://apps.apple.com/au/app/fitfocus/id6476741403">
              <PrimaryButton
                value="Apple"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="https://play.google.com/store/apps/details?id=io.fitfocus.app">
              <PrimaryButton
                value="Android"
                size="large"
                variant="contained"
                color="primary"
                fullWidth
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </FormContainer>
  );
};
