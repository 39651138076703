import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  MenuItem,
  Menu,
  Tab,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { useTypedSelector } from "shared/stores";
import { createInvoicesRows } from "shared/helpers";
import { sharedStyles } from "shared/themes/shared/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  ClientInvoice,
  useGetInvoicesCountQuery,
  useGetInvoicesQuery,
} from "shared/api";
import { MoreInfoButton } from "pages/Programs/AdminPrograms/ui/MoreInfoButton";
import { InvoiceStatusItem } from "./InvoiceStatusItem";
import { InvoiceChangeStatusModal } from "./InvoiceChangeStatusModal";

interface BillingContainerProps {
  openSidebar: () => void;
}

const BillingContainer: FC<BillingContainerProps> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);

  const [selectedClientInvoice, setSelectedClientInvoice] =
    useState<ClientInvoice>();

  const open = Boolean(anchorEl);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { data: invoicesCount } = useGetInvoicesCountQuery();

  const { data: invoices, isLoading } = useGetInvoicesQuery({
    count: pageSize,
    page,
    countOnly: false,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [rowCountState, setRowCountState] = useState(invoicesCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      invoicesCount !== undefined ? invoicesCount : prevRowCountState
    );
  }, [invoicesCount, setRowCountState]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    setOpenChangeStatusModal(!openChangeStatusModal);
    handleClose();
  };

  const columns: GridColDef[] = [
    {
      field: "amountDue",
      headerName: t("billing.invoiceAmount"),
      flex: 1,
      sortable: false,
    },
    {
      field: "customerName",
      headerName: t("billing.client"),
      flex: 1,
      sortable: false,
    },
    {
      field: "invoiceStatus",
      headerName: t("billing.invoiceStatus"),
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        return <InvoiceStatusItem status={params.value} />;
      },
    },
    {
      field: "dueDate",
      headerName: t("billing.invoiceDueDate"),
      flex: 1,
      sortable: false,
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          // eslint-disable-next-line
          <>
            {params.row.invoiceStatus === "open" ? (
              <>
                <MoreInfoButton handleClick={handleClick} />
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      boxShadow: "none",
                      border: "1px solid #EEEEEE",
                    },
                    "& .MuiList-root": { padding: "0px" },
                  }}
                >
                  <MenuItem
                    onClick={handleChangeStatus}
                    sx={{
                      border: "1px solid #EEEEEE",
                      color: `${Colors.gray[1900]}`,
                    }}
                  >
                    {t("billing.changeStatus")}
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </>
        );
      },
      filterable: false,
    },
  ];

  const rows = createInvoicesRows(invoices!);

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("billing.title")}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "-20px",
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          Manage all your billing in one place
        </Typography>
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Invoices" value="1" sx={{ textTransform: "none" }} />
            <Tab label="Memberships" value="2" sx={{ textTransform: "none" }} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0, paddingTop: 2 }}>
          <Box sx={sharedStyles.containers.dataGrid.wrapper}>
            <Box sx={{ height: "700px" }}>
              <DataGrid
                headerHeight={36}
                rowHeight={52}
                pageSize={pageSize}
                onPageChange={(newPage) => setPage(newPage + 1)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pagination
                columns={columns}
                rows={rows || []}
                disableColumnFilter
                rowCount={rowCountState}
                onRowClick={(params) => {
                  setSelectedClientInvoice(params.row as ClientInvoice);
                }}
                paginationMode="server"
                sx={{
                  "& .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                  "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                    bgcolor: `${Colors.gray[1700]}`,
                  },
                  "& .MuiDataGrid-menuIconButton": {
                    display: "none",
                  },
                }}
              />
              <InvoiceChangeStatusModal
                isOpenModal={openChangeStatusModal}
                handleCloseModal={() =>
                  setOpenChangeStatusModal(!openChangeStatusModal)
                }
                invoice={selectedClientInvoice}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0, paddingTop: 2 }}>
          Memberships
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export { BillingContainer };
