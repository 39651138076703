import { FC, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Skeleton,
  CardContent,
  Card,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import {
  useGetWellbeingDashboardForUserQuery,
  XYChartData,
  WellbeingDashboardRequest,
} from "shared/api";
import { WellbeingChart } from "pages/Dashboard/AdminDashboard/WellbeingChart";
import { TrendingUp, TrendingDown, InfoOutlined } from "@mui/icons-material";
import { getGraphicSvg } from "shared/themes";

interface EmplolyeeUserProfileInsightPageProps {
  userId: number;
}

const EmplolyeeUserProfileInsightPage: FC<
  EmplolyeeUserProfileInsightPageProps
> = ({ userId }) => {
  const [dateFrequency, setDateFrequency] = useState("month");

  const { data: wellbeingData, isLoading: wellbeingDataLoading } =
    useGetWellbeingDashboardForUserQuery({
      userId,
      dateFrequency,
    } as WellbeingDashboardRequest);

  const isApiLoading = wellbeingDataLoading;

  const isTrendingUp = (trendData: XYChartData[]) => {
    if (trendData.length < 2) return false;
    return trendData[trendData.length - 1].value > trendData[0].value;
  };

  const trendIcon = (isTrendingUp: boolean) =>
    isTrendingUp ? (
      <TrendingUp color="success" />
    ) : (
      <TrendingDown color="error" />
    );

  const wellbeingTrendData = wellbeingData?.wellbeingChartData ?? [];

  const stepsTrendData = wellbeingData?.stepsChartData ?? [];

  const activeCaloriesTrendData = wellbeingData?.activeCaloriesData ?? [];

  const activeHoursTrendData = wellbeingData?.activeHoursData ?? [];

  const activityTrendData = wellbeingData?.activityData ?? [];

  const sleepDurationTrendData = wellbeingData?.sleepDurationTotalData ?? [];

  return (
    <Box>
      {isApiLoading && (
        <>
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={100} sx={{ mb: 1 }} />
        </>
      )}
      {!isApiLoading && !wellbeingData && (
        <Box>
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            There is no data available for the selected period. Data can take up
            to 72 hours to appear for new users.
          </Typography>
        </Box>
      )}

      {!isApiLoading && wellbeingData && wellbeingData?.wellbeingAverage && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Wellbeing
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.wellbeingAverage}
                      </Typography>
                      {trendIcon(isTrendingUp(wellbeingTrendData))}
                      <Tooltip
                        title="This score is calculated based on aggregated data from various health metrics, including activity, sleep, and mental health. The trend indicates whether the score has improved or declined over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      seriesName="Wellbeing"
                      chartSeriesData={wellbeingData?.wellbeingChartData ?? []}
                      minYValue={wellbeingTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        wellbeingTrendData[0].value
                      )}
                      maxYValue={wellbeingTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        wellbeingTrendData[0].value
                      )}
                    />
                  </div>
                  <Typography variant="body2" color="text.secondary">
                    {wellbeingData?.wellbeingSummary}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Steps
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.stepsTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(stepsTrendData))}
                      <Tooltip
                        title="The total number of steps taken by employees on average. The trend indicates whether the number of steps has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Steps"
                      chartSeriesData={stepsTrendData}
                      minYValue={stepsTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        stepsTrendData[0]?.value ?? 0
                      )}
                      maxYValue={stepsTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        stepsTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Active calories
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.activeCaloriesTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activeCaloriesTrendData))}
                      <Tooltip
                        title="The total number of active calories burned by employees on average. The trend indicates whether the number of active calories has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Active calories"
                      chartSeriesData={activeCaloriesTrendData}
                      minYValue={activeCaloriesTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activeCaloriesTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activeCaloriesTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activeCaloriesTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 4 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Active hours
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.activeHoursTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activeHoursTrendData))}
                      <Tooltip
                        title="The total number of hours spent being active by employees on average. The trend indicates whether the number of active hours has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Active hours"
                      chartSeriesData={activeHoursTrendData}
                      minYValue={activeHoursTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activeHoursTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activeHoursTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activeHoursTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Sleep hours
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.sleepDurationTotal.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(sleepDurationTrendData))}
                      <Tooltip
                        title="The total number of hours spent sleeping by employees on average. The trend indicates whether the number of hours spent sleeping has increased or decreased over the recent period."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Sleep hours"
                      chartSeriesData={sleepDurationTrendData}
                      minYValue={sleepDurationTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        sleepDurationTrendData[0]?.value ?? 0
                      )}
                      maxYValue={sleepDurationTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        sleepDurationTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 4 }} />

          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <Card
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
                sx={{ height: "100%" }}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="h6"
                      className="text-xl font-semibold text-gray-800"
                    >
                      Activity
                    </Typography>
                    <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" sx={{ mr: 1 }}>
                        {wellbeingData?.activityAverage.toLocaleString()}
                      </Typography>
                      {trendIcon(isTrendingUp(activityTrendData))}
                      <Tooltip
                        title="The average of activity levels of employees. The trend indicates whether the activity levels have increased or decreased over the recent period. This is a measure of various metrics such as steps, floors climbed and general movement."
                        arrow
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <div className="mt-6 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl">
                    <WellbeingChart
                      key={dateFrequency}
                      seriesName="Active hours"
                      chartSeriesData={activityTrendData}
                      minYValue={activityTrendData.reduce(
                        (min, p) => (p.value < min ? p.value : min),
                        activityTrendData[0]?.value ?? 0
                      )}
                      maxYValue={activityTrendData.reduce(
                        (max, p) => (p.value > max ? p.value : max),
                        activityTrendData[0]?.value ?? 0
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export { EmplolyeeUserProfileInsightPage };
