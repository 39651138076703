import { FC } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Card, Typography, Box } from "@mui/material";
import { Colors } from "shared/themes/Colors";
import { Routes } from "shared/routers";
import { Challenge } from "shared/api";

interface ChallengeCardProps {
  challenge: Challenge;
}

const ChallengeCard: FC<ChallengeCardProps> = ({ challenge }) => {
  const getBackgroundColor = (status: string) => {
    switch (status) {
      case "Completed":
        return Colors.red[100];
      case "Inactive":
        return Colors.gray[100];
      case "Scheduled":
        return Colors.blue[500];
      default:
        return Colors.green[100];
    }
  };

  const getStatusText = (status: string) => {
    return status;
  };

  return (
    <Link
      to={`${Routes.challenges.url}/${challenge.id}`}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          width: "100%",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          padding: "16px",
          boxShadow: "none",
          mb: "16px",
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "16px",
              color: Colors.oxford[1100],
            }}
          >
            {challenge.name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "12px",
              color: "white",
              backgroundColor: getBackgroundColor(
                challenge.challengeStatus.toString()
              ),
              borderRadius: "12px",
              padding: "4px 8px",
            }}
          >
            {getStatusText(challenge.challengeStatus.toString())}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "14px",
            color: Colors.oxford[900],
            mt: 1,
          }}
        >
          {`${dayjs(challenge.startDate).format("DD MMM YYYY")} - ${dayjs(
            challenge.endDate
          ).format("DD MMM YYYY")}`}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: "14px",
            color: Colors.oxford[900],
            mt: 1,
          }}
        >
          {challenge.description}
        </Typography>
      </Card>
    </Link>
  );
};

export { ChallengeCard };
