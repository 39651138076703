import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AcknowledgeFeedback,
  DashboardResponse,
  AthleteDashboardRequest,
  WellbeingDashboardResponse,
  WellbeingDashboardRequest,
} from "./models";
import { WorkoutResponse } from "../athlete/model";

export const dashboardApi = createApi({
  baseQuery,
  reducerPath: "dashboardApi",
  tagTypes: ["Dashboard", "Workouts"],
  endpoints: (builder) => ({
    getDashboardInfo: builder.query<DashboardResponse, void>({
      query: () => ({
        url: "/dashboard",
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),
    getAthleteDashboardWorkouts: builder.query<
      WorkoutResponse,
      AthleteDashboardRequest
    >({
      query: (body) => ({
        url: `/dashboard/athlete-workout-programs?userId=${body.userId}&workoutProgramId=${body.workoutProgramId}`,
        method: "GET",
      }),
      providesTags: ["Workouts"],
    }),
    getAcknowledgeFeedback: builder.query<AcknowledgeFeedback[], number>({
      query: () => ({
        url: `/workout-program-notes/`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),
    updateAcknowledgeFeedback: builder.mutation<void, AcknowledgeFeedback>({
      query: (body) => ({
        url: `/workout-program-notes/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Dashboard"],
    }),
    getWellbeingDashboardForAllUsers: builder.query<
      WellbeingDashboardResponse,
      WellbeingDashboardRequest
    >({
      query: (params) => ({
        url: `/dashboard/employee-wellbeing-dashboard/`,
        method: "GET",
        params,
      }),
      providesTags: ["Dashboard"],
    }),
    getWellbeingDashboardForUser: builder.query<
      WellbeingDashboardResponse,
      WellbeingDashboardRequest
    >({
      query: (params) => ({
        url: `/dashboard/employee-wellbeing-dashboard/${params.userId}`,
        method: "GET",
        params,
      }),
      providesTags: ["Dashboard"],
    }),
  }),
});

export const {
  useGetDashboardInfoQuery,
  useGetAthleteDashboardWorkoutsQuery,
  useLazyGetAthleteDashboardWorkoutsQuery,
  useGetAcknowledgeFeedbackQuery,
  useUpdateAcknowledgeFeedbackMutation,
  useGetWellbeingDashboardForAllUsersQuery,
  useGetWellbeingDashboardForUserQuery,
} = dashboardApi;
