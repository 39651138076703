import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "shared/themes";
import { sharedStyles } from "shared/themes/shared/styles";
import { WorkoutProgram } from "shared/api";
import { OrganisationClientSelector } from "pages/UserNotes/OrganisationClientSelector";
import { Device } from "@capacitor/device";
import { LiveTrainingWorkoutsSelector } from "./LiveTrainingWorkoutsSelector";
import { LiveTrainingSelectedWorkout } from "./LiveTrainingSelectedWorkout";
import { LiveTrainingWorkoutProgramsSelector } from "./LiveTrainingWorkoutProgramsSelector";

interface LiveTrainingContentProps {
  preselectedUserId?: number;
  isMobile?: boolean;
}

const LiveTrainingContent: FC<LiveTrainingContentProps> = ({
  preselectedUserId = null,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(
    preselectedUserId
  );
  const [selectedWorkoutId, setSelectedWorkoutId] = useState<number | null>(
    null
  );

  const [selectedWorkoutProgramId, setSelectedWorkoutProgramId] = useState<
    number | null
  >(null);

  const [workoutPrograms, setWorkoutPrograms] = useState<WorkoutProgram[]>();

  const handleSelectUserId = (id: number | null) => {
    setSelectedUserId(id!);
    setSelectedWorkoutId(null);
    setSelectedWorkoutProgramId(null);
  };

  useEffect(() => {
    setSelectedWorkoutId(null);
  }, [selectedWorkoutProgramId]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleProgressWorkout = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: isMobile ? 0 : "8px",
        py: isMobile ? `calc(64px + env(safe-area-inset-top))` : "32px",
        px: isMobile ? "16px" : "32px",
        mb: isMobile ? 0 : "16px",
        overflowY: "auto",
        minHeight: isMobile ? "100vh" : "600px",
        maxHeight: isMobile ? "100vh" : "80vh",
      }}
    >
      {!isMobile && (
        <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
          <Typography sx={sharedStyles.headings.dataGrid}>
            {t("live-training.sub-title")}
          </Typography>
        </Box>
      )}
      {!preselectedUserId && (
        <OrganisationClientSelector
          setSelectedUserId={handleSelectUserId}
          selectedUserId={selectedUserId}
        />
      )}
      <LiveTrainingWorkoutProgramsSelector
        selectedUserId={selectedUserId}
        setSelectedWorkoutProgramId={setSelectedWorkoutProgramId}
        workoutPrograms={workoutPrograms}
        setWorkoutPrograms={setWorkoutPrograms}
      />
      {selectedWorkoutProgramId && (
        <LiveTrainingWorkoutsSelector
          selectedUserId={selectedUserId}
          setSelectedWorkoutId={setSelectedWorkoutId}
          selectedWorkoutProgramId={selectedWorkoutProgramId}
          ref={buttonRef}
        />
      )}
      {selectedWorkoutId && (
        <LiveTrainingSelectedWorkout
          selectedUserId={selectedUserId}
          selectedWorkoutId={selectedWorkoutId}
          handleProgressWorkout={handleProgressWorkout}
        />
      )}
    </Box>
  );
};

export { LiveTrainingContent };
