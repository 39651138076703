import React from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Grid,
  Skeleton,
  Card,
  CardMedia,
  CardContent,
  Button,
  Link,
} from "@mui/material";
import {
  UserLeaderboardResponse,
  useGetCurrentPointBalanceQuery,
  useGetPartnersQuery,
} from "shared/api";

interface LeaderboardScoreCardProps {
  leaderboardData: UserLeaderboardResponse | undefined;
  open?: boolean;
  onClose?: () => void;
  isFullScreen?: boolean;
}

export const LeaderboardScoreCard: React.FC<LeaderboardScoreCardProps> = ({
  leaderboardData,
  open,
  onClose,
  isFullScreen,
}) => {
  const dailyScores = leaderboardData?.dailyScores || [];

  const { data: currentPointBalance, isLoading } =
    useGetCurrentPointBalanceQuery();

  const { data: partners, isLoading: partnersLoading } = useGetPartnersQuery();

  const chartData = {
    series: [
      {
        name: "Points earned",
        data: [...dailyScores].reverse().map((score) => score.value),
      },
    ],
    xaxis: {
      categories: [...dailyScores]
        .reverse()
        .map((score) => dayjs(score.date).format("dd").charAt(0)),
    },
  };

  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            mt: 4,
            p: 2,
            borderRadius: 2,
            backgroundColor: "rgba(255,255,255,0.15)",
            backdropFilter: "blur(8px)",
          }}
        >
          <Skeleton
            variant="text"
            width={140}
            height={32}
            sx={{ bgcolor: "rgba(255,255,255,0.2)" }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            textAlign: "left",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            },
          }}
        >
          {currentPointBalance ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Points balance
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    color: "#333",
                    mt: 1,
                  }}
                >
                  {(
                    currentPointBalance?.totalPointsBalance ?? 0
                  ).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    options={{
                      chart: {
                        id: "basic-bar",
                        toolbar: {
                          show: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          borderRadius: 7,
                          borderRadiusApplication: "end",
                          columnWidth: "70%",
                        },
                      },
                      grid: {
                        show: false,
                      },
                      fill: {
                        colors: ["#4245FF", "#2F32FF", "#7A7DFF"],
                      },
                      yaxis: {
                        labels: {
                          show: false,
                        },
                      },
                      xaxis: {
                        ...chartData.xaxis,
                        tickAmount: 7,
                        labels: {
                          show: true,
                          style: {
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            cssClass: "apexcharts-xaxis-label",
                          },
                        },
                      },
                    }}
                    series={chartData.series}
                    type="bar"
                    width="100%"
                    height="120"
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}
              >
                Points balance
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  color: "#333",
                  mt: 1,
                }}
              >
                0
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          p: 2,
          borderRadius: 2,
          backgroundColor: "rgba(255,255,255,0.15)",
          backdropFilter: "blur(8px)",
        }}
      >
        {partnersLoading ? (
          <Skeleton
            variant="text"
            width={140}
            height={32}
            sx={{ bgcolor: "rgba(255,255,255,0.2)" }}
          />
        ) : (
          <Grid container spacing={2}>
            <Typography variant="h6" color="textSecondary">
              Partners
            </Typography>
            <Typography variant="body1" color="textSecondary">
              We partner with industry leading brands to bring you exclusive
              offers to fuel your health and fitness journey.
            </Typography>
            {partners?.map((partner) => (
              <Grid item xs={6} sm={6} md={4} key={partner.id}>
                <Link
                  href={partner.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Card sx={{ height: "100%" }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={partner.logoUrl || ""}
                      alt={partner.name}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {partner.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {partner.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
