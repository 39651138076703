import { UserRole } from "../auth/models";

export interface UserLiftingProfileType {
  id: number;
  dateTime: string;
  liftingProfileType: string;
  userId: number;
}
export interface UserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userRole: UserRole;
  created: string;
  updated: string;
  isVerified: boolean;
  swapExercisesEnabled: boolean;
  jwtToken: string;
  refreshToken: string;
  organisationId: number;
  userLiftingProfileType: UserLiftingProfileType;
  profileImageUrl: string;
  organisationType: string;
}

export interface ClientResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userRole: UserRole;
  created: string;
  updated: string;
  isVerified: boolean;
  organisationId: number;
  userStatus: string;
  userCategory: string;
  userLiftingProfileType: UserLiftingProfileType;
  profileImageUrl: string;
  // These fields are only used for the userprofile page for the banner
  unreadCount: number;
}

export enum BillingFrequency {
  Undefined = 0,
  Weekly = 1,
  Fortnightly = 2,
  Monthly = 3,
  Quarterly = 4,
  Biannually = 5,
  Annually = 6,
}

export interface UserProfileItemResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  profileImageUrl: string;
  myFitnessPalUsername: string;
  userRole: string;
  created: string;
  updated: string;
  dateOfBirth: string;
  isVerified: boolean;
  organisationId: number;
  userStatus: string;
  userCategory: string;
  phoneNumber: string;
  userLiftingProfileType: UserLiftingProfileType;
  assignedFitnessProfessionalId?: number;
  verificationUrl: string;
  swapExercisesEnabled: boolean;
  // These fields are only used for the userprofile page for the banner
  currentWorkoutProgramId: number;
  currentWorkoutProgramName: string;
  workoutsCompletedLast7Days: number;
  workoutsCompletedLast30Days: number;
  billingFrequency: string;
  billingStartDate: string;
  billingEndDate: string;
  emergencyContactName: string;
  emergencyContactNumber: string;
  emergencyContactRelationship: string;
  emergencyContactEmail: string;
}

export interface UserProfileItemParams {
  userId: number;
}

export interface ClientListParams {
  page?: number;
  count?: number;
  countOnly?: boolean;
  userRole?: string;
  userCategory?: string;
  orderByName?: boolean;
  includeInactive?: boolean;
  queryString?: string;
}

export interface InviteClientParams {
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  userCategory: string;
  liftingProfileType: string;
  assignedFitnessProfessionalId: number | null;
}

export interface InviteUserParams {
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  organisationId: number;
  liftingProfileType: string;
}

export interface WorkoutProgramUploadSubmitValues {
  formData: FormData;
}

export interface UpdateClientParams {
  email?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  userRole?: string;
  userStatus?: string;
  userCategory?: string;
  assignedFitnessProfessionalId?: number;
  userLiftingProfileType?: string;
  myFitnessPalUsername?: string;
  swapExercisesEnabled?: string;
  billingFrequency?: BillingFrequency;
  billingStartDate?: string;
  billingEndDate?: string;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  emergencyContactRelationship?: string;
  emergencyContactEmail?: string;
}
export interface PaymentDetailsParams {
  UserId: string;
  OrganisationId: string;
  CardToken: string;
}

export interface SubscriptionResponse {
  subscriptionId: string;
  subscriptionStatus: string;
  subscriptionName: string;
  nextInvoiceDate: string;
  subscriptionFrequency: string;
  subscriptionAmount: string;
  productId: string;
  priceId: string;
  userId: number;
}

export interface SubscriptionDetailsParams {
  UserId: string;
  OrganisationId: string;
}

export interface DeleteSubscriptionParams {
  UserId: string;
  OrganisationId: string;
  SubscriptionId: string;
  CancelNow?: boolean | null;
  CancelAtPeriodEnd?: boolean | null;
  CancelDate?: string | null;
}

export interface SubscriptionItemResponse {
  name: string;
  prices: SubscriptionPrice[];
  description: string;
  active: boolean;
  id: string;
}

export interface SubscriptionPrice {
  id: string;
  amount: string;
  active: boolean;
  billingType: string;
  billingScheme: string;
  billingInterval: string;
  rawAmount: number;
}

export interface AddSubscriptionParams {
  UserId: string;
  OrganisationId: string;
  PriceIds: string[] | [];
  StartDate: string;
  EndDate: string;
}

export interface UploadProfileImageParams {
  userId: number;
  file: File;
}
