import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import { CurrentPointBalanceResponse } from "./models";

export const pointsApi = createApi({
  baseQuery,
  reducerPath: "pointsApi",
  tagTypes: ["Points"],
  endpoints: (builder) => ({
    getCurrentPointBalance: builder.query<CurrentPointBalanceResponse, void>({
      query: () => ({
        url: `points/current-balance`,
        method: "GET",
      }),
      providesTags: ["Points"],
    }),
  }),
});

export const { useGetCurrentPointBalanceQuery } = pointsApi;
