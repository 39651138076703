import { FC } from "react";
import { Typography, Icon } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Colors } from "../../../themes";
import { Routes } from "../../../routers";

const SidebarWorkplaceWellbeingOrganisation: FC = () => {
  return (
    <Box>
      <Box
        sx={{
          width: "172px",
          minHeight: "20px",
          maxHeight: "20px",
          display: "flex",
          alignItems: "center",
          padding: 0,
          marginBottom: "15px",
        }}
      >
        <Icon
          fontSize="small"
          baseClassName="material-icons-outlined"
          sx={{ color: `${Colors.gray[900]}` }}
        >
          settings
        </Icon>
        <Typography
          sx={{
            m: 0,
            ml: "10px",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "140%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NavLink
            to={`${Routes.organisation.url}`}
            style={({ isActive }) =>
              isActive
                ? {
                    color: `${Colors.blue[1200]}`,
                    textDecoration: "none",
                  }
                : {
                    color: `${Colors.gray[900]}`,
                    textDecoration: "none",
                  }
            }
          >
            Organisation
          </NavLink>
        </Typography>
      </Box>
    </Box>
  );
};

export { SidebarWorkplaceWellbeingOrganisation };
