import { FC } from "react";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { Colors } from "shared/themes";

interface WellbeingChartProps {
  seriesName: string;
  chartSeriesData: { date: string; value: number }[];
  minYValue: number;
  maxYValue: number;
}

const WellbeingChart: FC<WellbeingChartProps> = ({
  seriesName,
  chartSeriesData,
  minYValue,
  maxYValue,
}) => {
  const chartOptions = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: { width: 2 },
    grid: {
      borderColor: "#f1f5f9",
      strokeDashArray: 4,
      xaxis: { lines: { show: true } },
      padding: { top: -20, bottom: 0 },
    },
    xaxis: {
      categories: chartSeriesData.map((data) =>
        dayjs(data.date).format("MMM D")
      ),
      tickAmount: 5,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { style: { colors: "#94a3b8", fontSize: "12px" } },
    },
    yaxis: {
      min: Math.floor(minYValue * 0.95),
      max: Math.ceil(maxYValue * 1.05),
      tickAmount: 5,
      labels: {
        style: { colors: "#94a3b8", fontSize: "12px" },
        formatter: (value: number) => Math.round(value).toLocaleString(),
      },
    },
    colors: ["#8b5cf6", Colors.blue[500]],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 0.8,
        stops: [5, 100],
      },
    },
    tooltip: {
      theme: "light",
      y: { formatter: (value: number) => Math.round(value).toLocaleString() },
    },
  };

  const transformedData = [
    {
      name: seriesName,
      data: chartSeriesData.map((data) => data.value),
    },
  ];

  return (
    <Chart
      key={JSON.stringify(chartSeriesData)}
      options={chartOptions}
      series={transformedData}
      type="area"
      height="100%"
    />
  );
};

export { WellbeingChart };
