import { FC, useState } from "react";
import { IconButton, Card, CardContent, Typography, Box } from "@mui/material";
import { Colors } from "shared/themes";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";
import { UserPersonalRecordsModal } from "./UserPersonalRecordsModal";

type UserProfilePersonalBestsProps = {
  preselectedUserId: number;
};

export const UserProfilePersonalBests: FC<UserProfilePersonalBestsProps> = ({
  preselectedUserId: userId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Card
      sx={{
        width: "100%",
        bgcolor: Colors.gray[100],
        borderRadius: "8px",
        mb: "16px",
      }}
    >
      <CardContent
        sx={{
          padding: "8px",
          ml: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <OutlinedIcon
            iconName="emoji_events"
            style={{
              color: `${Colors.blue[1200]}`,
              fontSize: "32px",
              marginRight: "4px",
            }}
          />
          <Typography variant="h6">Personal Bests</Typography>
        </Box>
        <IconButton color="primary" onClick={() => setIsOpenModal(true)}>
          <OutlinedIcon
            iconName="edit"
            style={{ color: `${Colors.blue[1200]}` }}
          />
        </IconButton>
      </CardContent>
      <UserPersonalRecordsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        userId={userId}
      />
    </Card>
  );
};
