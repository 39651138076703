import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Icon,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { WorkoutSession, WorkoutProgramStatus } from "shared/api";
import { useTypedDispatch } from "shared/stores";
import { Colors } from "shared/themes";
import { setCurrentWorkout } from "shared/stores/workout";
import { Routes } from "shared/routers";
import { AnyType } from "shared/helpers";

interface SessionCardProps {
  image: string;
  weekNumber?: number;
  dayNumber?: number;
  workoutStatus?: string;
  title?: string;
  id?: number;
  item?: WorkoutSession;
  isWorkoutProgramCompleted?: boolean;
}
const SessionCard: FC<SessionCardProps> = ({
  image,
  dayNumber,
  weekNumber,
  workoutStatus,
  title,
  id,
  item,
  isWorkoutProgramCompleted = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const isInProgressWorkout =
    workoutStatus === WorkoutProgramStatus.IN_PROGRESS;

  const link = isWorkoutProgramCompleted
    ? `${Routes.workouts.url}/${id}/completed`
    : `${Routes.workouts.url}/${id}`;

  return (
    <Link to={link}>
      <Card
        onClick={() => dispatch(setCurrentWorkout(item as AnyType))}
        sx={{
          width: "100%",
          height: "184px",
          position: "relative",
          mb: "16px",
        }}
      >
        <CardMedia
          sx={{ width: "100%", height: "100%", position: "relative" }}
          image={image}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        />
        <CardContent
          sx={{
            position: "absolute",
            top: "40%",
            width: "100%",
            height: "60%",
            padding: "0px 20px 0px 20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ height: "120%" }}>
              <Box
                sx={{
                  width: "65px",
                  height: "24px",
                  borderRadius: "16px",
                  bgcolor: `${Colors.gray[400]}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "16px",
                }}
              >
                {!!weekNumber && (
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "0.16px",
                      color: `${Colors.blue[1300]}`,
                    }}
                  >
                    {t("session-card.week")} {weekNumber}
                  </Typography>
                )}
              </Box>

              {!!dayNumber && (
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: `${Colors.gray[100]}`,
                    mb: "8px",
                    // add a black text shadow that appears as an outline for the text
                    textShadow: "0px 12px 12px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  {t("session-card.session")} {dayNumber}{" "}
                  {title && `: ${title}`}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                height: "120%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                paddingBottom: "8px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  p: "4px 16px",
                  borderRadius: "5px",
                  background: `${Colors.gray[100]}`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.4px",
                    color: `${Colors.blue[1300]}`,
                    textTransform: "none",
                  }}
                >
                  {isInProgressWorkout
                    ? t("session-card.resume-workout")
                    : t("session-card.preview-workout")}
                </Box>

                {isInProgressWorkout && (
                  <Icon
                    sx={{
                      marginLeft: "6px",
                      color: `${Colors.blue[1300]}`,
                    }}
                  >
                    arrow_forward
                  </Icon>
                )}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export { SessionCard };
