export const formatDuration = (minutes: number) => {
  const hrs = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  return `${hrs}h ${mins}m`;
};

export const getGradientForScore = (title: string) => {
  switch (title.toLowerCase()) {
    case "high":
      return "linear-gradient(90deg, #2196f3, #00e676, #76ff03)";
    case "medium":
      return "linear-gradient(90deg, #76ff03, #ffeb3b, #ffd700)";
    case "low":
      return "linear-gradient(90deg, #ff9900, #ffcc00)";
    case "minimal":
      return "linear-gradient(90deg, #ff3300, #ff6600)";
    case "empty":
      return "linear-gradient(90deg, #e0e0e0, #e0e0e0)";
    default:
      return "linear-gradient(90deg, #e0e0e0, #e0e0e0)";
  }
};

export const formatPercentage = (value: number): string => {
  return `${Math.round(value)}%`;
};

export enum PerformanceLevel {
  EMPTY = "EMPTY",
  POOR = "POOR",
  AVERAGE = "AVERAGE",
  GOOD = "GOOD",
}

export type ScoreType = "activity" | "wellbeing" | "readiness" | "sleep";

export const determinePerformanceLevel = (
  value: number,
  target: number,
  scoreType: ScoreType = "activity"
) => {
  const score = (value / target) * 100;

  switch (scoreType) {
    case "activity":
      if (score >= 76) return "high";
      if (score >= 61) return "medium";
      if (score >= 46) return "low";
      return "minimal";

    case "wellbeing":
      if (score >= 81) return "high";
      if (score >= 66) return "medium";
      if (score >= 51) return "low";
      return "minimal";

    case "readiness":
      if (score >= 81) return "high";
      if (score >= 61) return "medium";
      if (score >= 41) return "low";
      return "minimal";

    case "sleep":
      if (score >= 81) return "high";
      if (score >= 66) return "medium";
      if (score >= 51) return "low";
      return "minimal";

    default:
      return "minimal";
  }
};

export const getFriendlyNameOfState = (state: string) => {
  if (state.toLowerCase() === "minimal") return "Needs attention";
  if (state.toLowerCase() === "low") return "Needs attention";
  if (state.toLowerCase() === "medium") return "Good";
  if (state.toLowerCase() === "high") return "Optimal";
  return state;
};
