import React, { FC } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useGetAthleteLeaderboardQuery } from "shared/api/healthScores/healthScores";
import { styled } from "@mui/system";
import { LeaderboardScoreCard } from "../Dashboard/AthleteDashboard/LeaderboardScoreCard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LeaderboardContainer = styled(Box)({
  padding: "24px",
});
interface AthleteLeaderboardProps {
  open: boolean;
  onClose: () => void;
}

export const AthleteLeaderboard: FC<AthleteLeaderboardProps> = ({
  open,
  onClose,
}) => {
  const { data: leaderboardData, isLoading } = useGetAthleteLeaderboardQuery(
    {}
  );

  if (isLoading) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            background: `linear-gradient(
              126.67deg,
              #8B44AD 0%,
              #6C5CE7 50.52%,
              #4834D4 100%
            )`,
          }}
        >
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6">
              Points
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <LeaderboardContainer>
          <Skeleton variant="text" width={200} height={32} />
          <Skeleton variant="text" width={300} height={24} />
          <Skeleton variant="rectangular" height={400} sx={{ mt: 3 }} />
        </LeaderboardContainer>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6">
            Points
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <LeaderboardContainer>
          <Box sx={{ textAlign: "left", mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LeaderboardScoreCard
                  leaderboardData={leaderboardData}
                  isFullScreen
                />
              </Grid>
            </Grid>
          </Box>
        </LeaderboardContainer>
      </Box>
    </Dialog>
  );
};
