import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Colors } from "shared/themes";
import {
  useLazyGetUsersQuery,
  useGetUserByIdForUserNotesQuery,
} from "shared/api";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";

interface OrganisationClientSelectorProps {
  setSelectedUserId: (id: number | null) => void;
  selectedUserId: number | null;
}

const OrganisationClientSelector: FC<OrganisationClientSelectorProps> = ({
  setSelectedUserId,
  selectedUserId,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showResults, setShowResults] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(!selectedUserId);
  const [trigger, { data: users, isLoading }] = useLazyGetUsersQuery();
  const organisationType = useTypedSelector(userSelectors.organisationType);

  // Fetch selected user details
  const { data: selectedUser } = useGetUserByIdForUserNotesQuery(
    selectedUserId || 0,
    { skip: !selectedUserId }
  );

  const filteredUsers = users?.filter(
    (user) => user.userCategory.toLowerCase() !== "noncoaching"
  );

  const handleSearch = () => {
    if (!searchValue.trim()) return;
    setShowResults(true);
    trigger({
      userRole: organisationType === "WorkplaceWellbeing" ? "" : "athlete",
      orderByName: true,
      queryString: searchValue,
      count: 20,
      page: 1,
    });
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSelectUser = (userId: number) => {
    setSelectedUserId(userId);
    setSearchValue("");
    setShowResults(false);
    setIsSearchMode(false);
  };

  const handleChangeClient = () => {
    setSelectedUserId(null);
    setSearchValue("");
    setShowResults(false);
    setIsSearchMode(true);
  };

  // Hide results when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setShowResults(false);
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Prevent hiding results when clicking inside the component
  const handleBoxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Box sx={{ mt: 2 }} onClick={handleBoxClick}>
      <Box sx={{ position: "relative" }}>
        {!isSearchMode && selectedUser ? (
          <Box>
            <TextField
              fullWidth
              label="Selected Client"
              variant="outlined"
              value={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CheckCircleIcon
                      sx={{
                        color: Colors.green[500],
                        mr: 1,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="text"
              onClick={handleChangeClient}
              sx={{
                mt: 1,
                textTransform: "none",
              }}
            >
              Change Client
            </Button>
          </Box>
        ) : (
          <TextField
            fullWidth
            label="Search clients"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSearch}
                    edge="end"
                    size="small"
                    type="button"
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>

      {showResults && isSearchMode && (
        <Box sx={{ position: "relative" }}>
          {isLoading && (
            <Typography sx={{ mt: 2, color: Colors.gray[1200] }}>
              Searching...
            </Typography>
          )}

          {filteredUsers && filteredUsers.length > 0 && (
            <List
              sx={{
                mt: 2,
                maxHeight: 200,
                overflow: "auto",
                border: `1px solid ${Colors.gray[1600]}`,
                borderRadius: "4px",
                position: "absolute",
                left: 0,
                right: 0,
                zIndex: 1000,
                opacity: 1,
                bgcolor: "background.paper",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              {filteredUsers.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleSelectUser(user.id)}
                  sx={{
                    "&:hover": {
                      backgroundColor: Colors.gray[1700],
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "0.75rem", marginTop: "-2px" }}
                      >
                        {user.email && `e: ${user.email}`}
                        {user.phoneNumber && user.email && " • "}
                        {user.phoneNumber && `ph: ${user.phoneNumber}`}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}

          {filteredUsers && filteredUsers.length === 0 && (
            <Typography sx={{ mt: 2, color: Colors.gray[1200] }}>
              No clients found
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export { OrganisationClientSelector };
