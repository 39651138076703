import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import { LiveTrainingContent } from "./LiveTrainingContent";

const LiveTraining: FC = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  return (
    <Box
      sx={{
        ...(showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen),
        overflow: !matches ? "hidden" : "auto",
        height: !matches ? "100vh" : "auto",
        p: !matches ? 0 : 2,
      }}
    >
      {matches && (
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>
            {t("live-training.title")}
          </Typography>
        </Box>
      )}
      <LiveTrainingContent isMobile={!matches} />
    </Box>
  );
};

export { LiveTraining };
