import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  GetLeaderboardParams,
  HealthSummary,
  LeaderboardResponse,
  ProfileToken,
  UserLeaderboardResponse,
  PartnerResponse,
  HealthSummaryResponse,
} from "./models";

export const healthScoresApi = createApi({
  baseQuery,
  reducerPath: "healthScoresApi",
  tagTypes: [
    "HealthScores",
    "ProfileToken",
    "AthleteLeaderboard",
    "AdminLeaderboard",
  ],
  endpoints: (builder) => ({
    getHealthScoresForUser: builder.query<
      HealthSummary,
      { type: string; userId: number }
    >({
      query: ({ type, userId }) => `health-scores/${type}/${userId}`,
      providesTags: ["HealthScores"],
    }),
    getMostRecentHealthSummaryForUser: builder.query<
      HealthSummaryResponse,
      { type: string; userId: number }
    >({
      query: ({ type, userId }) => `health-scores/${type}/${userId}/summary`,
      providesTags: ["HealthScores"],
    }),
    getActivePartner: builder.query<PartnerResponse, {}>({
      query: () => `health-scores/active-partner`,
      providesTags: ["HealthScores"],
    }),
    getProfileTokenForUser: builder.query<ProfileToken, {}>({
      query: () => `health-scores/token`,
      providesTags: ["ProfileToken"],
    }),
    activateProfileToken: builder.mutation<void, void>({
      query: () => ({
        url: "health-scores/activate",
        method: "POST",
      }),
      invalidatesTags: ["HealthScores", "ProfileToken"],
    }),
    getAthleteLeaderboard: builder.query<
      UserLeaderboardResponse,
      GetLeaderboardParams
    >({
      query: (params) => ({
        url: `health-scores/leaderboard`,
        method: "GET",
        params,
      }),
      providesTags: ["AthleteLeaderboard"],
    }),
    getAdminLeaderboard: builder.query<
      LeaderboardResponse[],
      GetLeaderboardParams
    >({
      query: (params) => ({
        url: `health-scores/leaderboard`,
        method: "GET",
        params,
      }),
      providesTags: ["AdminLeaderboard"],
    }),
  }),
});

export const {
  useGetHealthScoresForUserQuery,
  useGetMostRecentHealthSummaryForUserQuery,
  useGetActivePartnerQuery,
  useGetProfileTokenForUserQuery,
  useActivateProfileTokenMutation,
  useGetAthleteLeaderboardQuery,
  useGetAdminLeaderboardQuery,
} = healthScoresApi;
