import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  Challenge,
  ChallengesRequestParams,
  CreateChallengeParams,
  ChallengeLeaderboardItem,
  UpdateChallengeParams,
  ChallengeUser,
  ChallengeDashboardResponse,
} from "./models";

export const challengesApi = createApi({
  baseQuery,
  reducerPath: "challengesApi",
  tagTypes: ["Challenge"],
  endpoints: (builder) => ({
    getChallenges: builder.query<Challenge[], ChallengesRequestParams>({
      query: (params) => ({
        url: `/challenges`,
        params,
      }),
      providesTags: ["Challenge"],
    }),
    getChallenge: builder.query<Challenge, number>({
      query: (id) => `/challenges/${id}`,
      providesTags: ["Challenge"],
    }),
    getChallengeLeaderboard: builder.query<ChallengeLeaderboardItem[], number>({
      query: (id) => `/challenges/${id}/leaderboard`,
      providesTags: ["Challenge"],
    }),
    getChallengesCount: builder.query<number, ChallengesRequestParams>({
      query: (params) => ({
        url: `/challenges`,
        params,
      }),
      providesTags: ["Challenge"],
    }),
    createChallenge: builder.mutation<Challenge, CreateChallengeParams>({
      query: (body) => ({
        url: `/challenges`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Challenge"],
    }),
    updateChallenge: builder.mutation<Challenge, UpdateChallengeParams>({
      query: (body) => ({
        url: `/challenges/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Challenge"],
    }),
    addUsersToChallenge: builder.mutation<
      ChallengeUser[],
      { challengeId: number; challengeUsers: ChallengeUser[] }
    >({
      query: ({ challengeId, challengeUsers }) => ({
        url: `/challenges/${challengeId}/users`,
        method: "POST",
        body: challengeUsers,
      }),
    }),
    removeUsersFromChallenge: builder.mutation<
      void,
      { challengeId: number; userIds: number[] }
    >({
      query: ({ challengeId, userIds }) => ({
        url: `/challenges/${challengeId}/users`,
        method: "DELETE",
        body: userIds,
      }),
    }),
    getCurrentActiveChallenge: builder.query<ChallengeDashboardResponse, void>({
      query: () => `/challenges/current-active`,
      providesTags: ["Challenge"],
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useGetChallengeQuery,
  useGetChallengeLeaderboardQuery,
  useGetChallengesCountQuery,
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
  useAddUsersToChallengeMutation,
  useRemoveUsersFromChallengeMutation,
  useGetCurrentActiveChallengeQuery,
} = challengesApi;
