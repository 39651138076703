import { FC, useState } from "react";
import { Box } from "@mui/system";
import {
  Icon,
  Modal,
  TextField,
  Typography,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Colors } from "../../themes";
import { useTypedSelector } from "../../stores";
import { userSelectors } from "../../stores/user";
import {
  useInviteClientMutation,
  useGetUsersQuery,
  ClientResponse,
} from "../../api";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnyType } from "../../helpers";

interface InviteClientModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  modalType: string;
}

interface SubmitValues {
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  userCategory: string;
  liftingProfileType: string;
  assignedFitnessProfessionalId: number | null;
}

const InviteClientSchema = Yup.object().shape({
  firstName: Yup.string().required(
    "invite-modal-validations.firstName-required"
  ),
  lastName: Yup.string().required("invite-modal-validations.lastName-required"),
  email: Yup.string()
    .email("invite-modal-validations.email-incorrect")
    .required("invite-modal-validations.email-required"),
});

const InviteClientModal: FC<InviteClientModalProps> = ({
  isOpenModal,
  handleCloseModal,
  modalType,
}) => {
  const { t } = useTranslation();
  const isAdmin = useTypedSelector(userSelectors.isAdmin);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [inviteClient, { isLoading }] = useInviteClientMutation();
  const [selectedUserValue, setSelectedUserValue] =
    useState<ClientResponse | null>(null);

  const {
    data: fitnessProfessionals,
    isLoading: isLoadingFitnessProfessionals,
  } = useGetUsersQuery({
    userRole: "admin",
    orderByName: true,
  });

  const onSubmit = async (params: SubmitValues) => {
    setErrorMessage("");

    try {
      await inviteClient(params).unwrap();

      setSuccessMessage(
        `${params.firstName} ${params.lastName} has been invited successfully`
      );
    } catch (error: AnyType) {
      setErrorMessage(error?.data?.message || t("errors.server-unable"));
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetForm();
      setSelectedUserValue(null);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userRole: "Athlete",
      userCategory: "Online",
      liftingProfileType: "GeneralFitness",
      assignedFitnessProfessionalId: null,
    },
    validationSchema: InviteClientSchema,
    onSubmit: (params) => {
      onSubmit(params);
    },
  });

  const displayAssignedCoach =
    !isLoadingFitnessProfessionals &&
    fitnessProfessionals !== undefined &&
    isAdmin;

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <div id="add-client-modal">
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "400px",
              height: "auto",
              background: `${Colors.gray[100]}`,
              borderRadius: "4px",
              padding: "16px 24px 24px",
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
              "& > *": {
                marginBottom: "16px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "45px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "140%",
                  color: `${Colors.blue[1300]}`,
                }}
              >
                {modalType === "employees"
                  ? "Invite employee"
                  : "Invite client"}
              </Typography>
              <Icon
                onClick={handleCloseModal}
                sx={{ color: `${Colors.gray[1400]}`, cursor: "pointer" }}
              >
                close_icon
              </Icon>
            </Box>
            <Typography
              sx={{
                width: "100%",
                height: "44px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.blue[1300]}`,
                marginBottom: "8px",
              }}
            >
              {modalType === "employees"
                ? "Invite an employee to join your workspace so they can easily track their progress"
                : "Invite a client to your organisation so they can easily manage and track their programs"}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{ width: "100%", height: "auto" }}
                type="text"
                value={values.firstName}
                label="First Name"
                onBlur={handleBlur("firstName")}
                onChange={handleChange("firstName")}
                error={!!errors.firstName}
                helperText={errors.firstName ? t(errors.firstName) : ""}
              />
              <TextField
                sx={{ width: "100%", height: "auto", ml: 1 }}
                type="text"
                value={values.lastName}
                label="Last Name"
                onBlur={handleBlur("lastName")}
                onChange={handleChange("lastName")}
                error={!!errors.lastName}
                helperText={errors.lastName ? t(errors.lastName) : ""}
              />
            </Box>
            <Box>
              <TextField
                sx={{ width: "100%", height: "auto" }}
                type="email"
                value={values.email}
                label="Email"
                onBlur={handleBlur("email")}
                onChange={handleChange("email")}
                error={!!errors.email}
                helperText={errors.email ? t(errors.email) : ""}
              />
            </Box>
            {modalType !== "employees" ? (
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ width: "100%", height: "auto" }}
                  variant="outlined"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Category
                  </InputLabel>
                  <Select
                    value={values.userCategory}
                    onChange={(_e) => {
                      handleChange("userCategory")(_e.target.value);
                    }}
                    onBlur={handleBlur("userCategory")}
                    error={!!errors.userCategory}
                    label="Category"
                  >
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="InPerson">In Person</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="NonCoaching">Non Coaching</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ width: "100%", height: "auto", ml: 1 }}
                  variant="outlined"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Lifting Type
                  </InputLabel>
                  <Select
                    value={values.liftingProfileType}
                    onChange={(_e) => {
                      handleChange("liftingProfileType")(_e.target.value);
                    }}
                    onBlur={handleBlur("liftingProfileType")}
                    error={!!errors.liftingProfileType}
                    label="Lifting Profile Type"
                  >
                    <MenuItem value="Undefined">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Powerlifting">Powerlifting</MenuItem>
                    <MenuItem value="OlympicWeightlifting">
                      Olympic Weightlifting
                    </MenuItem>
                    <MenuItem value="Bodybuilding">Bodybuilding</MenuItem>
                    <MenuItem value="Crossfit">Crossfit</MenuItem>
                    <MenuItem value="Strongman">Strongman</MenuItem>
                    <MenuItem value="Powerbuilding">Powerbuilding</MenuItem>
                    <MenuItem value="Calisthenics">Calisthenics</MenuItem>
                    <MenuItem value="GeneralFitness">General Fitness</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ) : null}
            {displayAssignedCoach && modalType !== "employees" ? (
              <FormControl
                sx={{ width: "100%", height: "auto" }}
                variant="outlined"
              >
                <Autocomplete
                  sx={{ width: "100%", mb: "24px" }}
                  options={fitnessProfessionals}
                  defaultValue={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select assigned coach"
                      placeholder="Coach"
                    />
                  )}
                  onChange={(_e, user) => {
                    setSelectedUserValue(user);
                    setFieldValue(
                      "assignedFitnessProfessionalId",
                      user?.id || 0
                    );
                  }}
                  value={selectedUserValue}
                  getOptionLabel={(item) =>
                    `${`${item?.firstName} ${item?.lastName}`} - ${item?.email}`
                  }
                />
              </FormControl>
            ) : null}
            <div id="invite-client-added-btn">
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "36px",
                  background: `${Colors.blue[1200]}`,
                }}
                size="large"
                loading={isLoading}
                disabled={
                  isLoading ||
                  !values.firstName ||
                  !values.lastName ||
                  !values.email ||
                  (modalType !== "employees" &&
                    (!values.assignedFitnessProfessionalId ||
                      !values.liftingProfileType ||
                      !values.userCategory))
                }
                variant="contained"
                onClick={() => handleSubmit()}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Invite {modalType === "employees" ? "employee" : "client"}
                </Typography>
              </LoadingButton>
            </div>
            {errorMessage && (
              <Alert
                sx={{
                  mt: 3,
                  width: "100%",
                }}
                variant="outlined"
                severity="error"
              >
                {errorMessage}
              </Alert>
            )}
            {successMessage && (
              <Alert
                sx={{
                  mt: 3,
                  width: "100%",
                }}
                variant="outlined"
                severity="success"
              >
                {successMessage}
              </Alert>
            )}
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export { InviteClientModal };
