export enum ChallengeTargetType {
  Undefined = 0,
  Wellbeing = 1,
  Activity = 2,
  SleepQuality = 3,
  Steps = 4,
  ActiveCalories = 5,
  ActiveHours = 6,
}

export enum ChallengeStatus {
  Undefined = 0,
  Active = 1,
  Inactive = 2,
  Completed = 3,
  Scheduled = 4,
}

export enum ChallengeCategory {
  Undefined = 0,
  Individual = 1,
  Team = 2,
}

export interface ChallengesRequestParams {
  count?: number;
  page?: number;
  active?: boolean;
  countOnly?: boolean;
}

export interface ChallengeLeaderboardItem {
  userId: number;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  value?: number;
  rank?: number;
}

export interface ChallengeUser {
  id: number;
  userId: number;
  challengeId: number;
  challengeTeamId?: number;
}
export interface ChallengeTeam {
  id: number;
  name: string;
  description: string;
  challengeId: number;
}

export interface CreateChallengeParams {
  name: string;
  description: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  challengeTargetType: ChallengeTargetType;
  challengeStatus: ChallengeStatus;
  targetValue?: number;
  challengeCategory: ChallengeCategory;
}

export interface Challenge {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  challengeTargetType: ChallengeTargetType;
  challengeStatus: ChallengeStatus;
  targetValue: number;
  organisationId: number;
  challengeUsers: ChallengeUser[];
  challengeTeams: ChallengeTeam[];
  challengeCategory?: ChallengeCategory;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  updatedByUserId: number;
  createdByUserId: number;
}

export interface UpdateChallengeParams {
  id: number;
  name: string;
  description: string;
  targetValue?: number;
  challengeStatus?: ChallengeStatus;
}

export interface ChallengeDashboardResponse {
  activeChallenge: Challenge;
  userRank?: number;
  userScore?: number;
}
