import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import {
  useGetUserPersonalRecordsQuery,
  useCreateUserPersonalRecordMutation,
  useUpdateUserPersonalRecordMutation,
  useDeleteUserPersonalRecordMutation,
} from "shared/api/userPersonalRecords/userPersonalRecordsApi";
import { useGetExercisesLibraryQuery } from "shared/api/exercises/exercises";
import { Colors } from "shared/themes";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserPersonalRecord } from "shared/api/userPersonalRecords/models";
import { ExerciseItem } from "shared/api/exercises/models";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import SearchIcon from "@mui/icons-material/Search";

interface UserPersonalRecordsProps {
  userId: number;
}

interface FormData {
  exercise: string;
  exerciseId?: number;
  weight: string;
  reps: string;
  is1RM: boolean;
}

const initialFormData: FormData = {
  exercise: "",
  exerciseId: undefined,
  weight: "",
  reps: "",
  is1RM: true,
};

type WeightUnit = "kg" | "lbs";

const convertWeight = (
  weight: string,
  from: WeightUnit,
  to: WeightUnit
): string => {
  const numWeight = parseFloat(weight);
  if (Number.isNaN(numWeight)) return "";

  if (from === to) return weight;
  if (to === "kg") return (numWeight * 0.453592).toFixed(1);
  return (numWeight * 2.20462).toFixed(1);
};

export const UserPersonalRecords: React.FC<UserPersonalRecordsProps> = ({
  userId,
}) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [editingRecord, setEditingRecord] = useState<UserPersonalRecord | null>(
    null
  );
  const [unit, setUnit] = useState<WeightUnit>("kg");
  const [deleteConfirmation, setDeleteConfirmation] =
    useState<UserPersonalRecord | null>(null);
  const [isAddFormExpanded, setIsAddFormExpanded] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showResults, setShowResults] = useState(false);

  const isAdmin = useTypedSelector(userSelectors.isAdmin);

  const { data: personalRecordsCount, isLoading: isLoadingCount } =
    useGetUserPersonalRecordsQuery({
      userId,
      countOnly: true,
    });

  const { data: personalRecords, isLoading: isLoadingRecords } =
    useGetUserPersonalRecordsQuery({
      userId,
      page: page + 1,
      count: 5,
    });

  const isLoading = isLoadingCount || isLoadingRecords;

  const { data: exercises } = useGetExercisesLibraryQuery(
    { queryString: searchTerm },
    { skip: !searchTerm || !isAdmin }
  );

  const [createPersonalRecord] = useCreateUserPersonalRecordMutation();
  const [updatePersonalRecord] = useUpdateUserPersonalRecordMutation();
  const [deletePersonalRecord] = useDeleteUserPersonalRecordMutation();

  useEffect(() => {
    if (typeof personalRecordsCount === "number") {
      setTotalCount(personalRecordsCount);
    }
  }, [personalRecordsCount]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleUnitChange = (
    _event: React.MouseEvent<HTMLElement>,
    newUnit: WeightUnit | null
  ) => {
    if (newUnit !== null) {
      // When editing, the form value is always in kg initially
      if (editingRecord && formData.weight) {
        const convertedWeight = convertWeight(formData.weight, "kg", newUnit);
        setFormData((prev) => ({
          ...prev,
          weight: convertedWeight,
        }));
      } else if (!editingRecord && formData.weight) {
        // Normal behavior for new records
        const convertedWeight = convertWeight(formData.weight, unit, newUnit);
        setFormData((prev) => ({
          ...prev,
          weight: convertedWeight,
        }));
      }
      setUnit(newUnit);
    }
  };

  const handleInputChange =
    (field: keyof FormData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: field === "is1RM" ? e.target.checked : e.target.value,
      }));
    };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { exercise, weight, reps, is1RM, exerciseId } = formData;
    if (!exercise || !weight || (!is1RM && !reps)) return;

    try {
      // Use the weight value as is, since it's already in the correct unit
      const recordValue = is1RM ? weight : `${weight} x ${reps}`;
      const notes = `[${is1RM ? "1RM" : "REPS"}] [${unit.toUpperCase()}]`;

      if (editingRecord) {
        await updatePersonalRecord({
          id: editingRecord.id,
          userId,
          name: exercise,
          value: recordValue,
          notes,
        });
        setEditingRecord(null);
      } else {
        await createPersonalRecord({
          userId,
          name: exercise,
          value: recordValue,
          notes,
          exerciseId,
        });
      }
      setFormData(initialFormData);
    } catch (error) {
      console.error("Failed to save personal record:", error);
    }
  };

  const handleEdit = (record: UserPersonalRecord) => {
    const is1RM = record.notes.includes("[1RM]");
    let weight = record.value;
    let reps = "";

    if (!is1RM) {
      const [w, r] = record.value.split(" x ");
      weight = w;
      reps = r;
    }

    // Parse the unit from notes and convert to kg if needed
    const unitMatch = record.notes.match(/\[(KG|LBS)\]/);
    const recordUnit = unitMatch
      ? (unitMatch[1].toLowerCase() as WeightUnit)
      : "kg";

    // Always set to kg mode when editing
    setUnit("kg");

    // Convert to kg if the record was in lbs
    const weightInKg =
      recordUnit === "lbs" ? convertWeight(weight, "lbs", "kg") : weight;

    setFormData({
      exercise: record.name,
      exerciseId: record.exerciseId,
      weight: weightInKg,
      reps,
      is1RM,
    });
    setEditingRecord(record);
    setSearchTerm(""); // Reset search term
    setShowResults(false); // Hide results dropdown
  };

  const handleCancelEdit = () => {
    setEditingRecord(null);
    setFormData(initialFormData);
  };

  const formatRecordValue = (record: UserPersonalRecord): string => {
    const is1RM = record.notes.includes("[1RM]");
    const unitMatch = record.notes.match(/\[(KG|LBS)\]/);
    const recordUnit = unitMatch
      ? (unitMatch[1].toLowerCase() as WeightUnit)
      : "kg";

    if (is1RM) {
      const displayWeight =
        unit !== recordUnit
          ? convertWeight(record.value, recordUnit, unit)
          : record.value;
      return `${displayWeight}${unit} (1RM)`;
    }

    const [weight, reps] = record.value.split(" x ");
    const displayWeight =
      unit !== recordUnit ? convertWeight(weight, recordUnit, unit) : weight;
    return `${displayWeight}${unit} x ${reps}`;
  };

  const handleDeleteClick = (record: UserPersonalRecord) => {
    setDeleteConfirmation(record);
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmation) {
      try {
        await deletePersonalRecord(deleteConfirmation.id);
        setDeleteConfirmation(null);
      } catch (error) {
        console.error("Failed to delete personal record:", error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation(null);
  };

  const handleSearch = () => {
    if (!formData.exercise.trim()) return;
    setSearchTerm(formData.exercise);
    setShowResults(true);
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSelectExercise = (exercise: ExerciseItem) => {
    setFormData((prev) => ({
      ...prev,
      exercise: exercise.name,
      exerciseId: exercise.id,
    }));
    setShowResults(false);
  };

  // Hide results when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setShowResults(false);
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Prevent hiding results when clicking inside the component
  const handleBoxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center" sx={{ py: 8 }}>
            <Typography>Loading personal bests...</Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (!personalRecords || personalRecords.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center" sx={{ py: 8 }}>
            <Typography variant="body2" sx={{ color: Colors.gray[600] }}>
              No personal bests recorded yet
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return personalRecords.map((record) => {
      const date = new Date(record.updatedAt).toLocaleDateString();
      return (
        <TableRow
          key={record.id}
          sx={{
            "&:hover": { backgroundColor: Colors.gray[50] },
            "&:last-child td": { borderBottom: 0 },
          }}
        >
          <TableCell sx={{ py: 2 }}>{record.name}</TableCell>
          <TableCell sx={{ py: 2 }}>{formatRecordValue(record)}</TableCell>
          <TableCell sx={{ py: 2 }}>{date}</TableCell>
          <TableCell sx={{ py: 2 }}>
            <Stack direction="row" spacing={1}>
              <IconButton
                size="small"
                onClick={() => handleEdit(record)}
                sx={{ color: Colors.blue[600] }}
              >
                <EditIcon fontSize="small" />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => handleDeleteClick(record)}
                sx={{ color: Colors.red[200] }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading personal bests...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${Colors.gray[200]}`,
          borderRadius: 2,
          mb: 3,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 600 }}>
              {editingRecord ? "Edit Personal Best" : "Add Personal Best"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ToggleButtonGroup
                value={unit}
                exclusive
                onChange={handleUnitChange}
                size="small"
                sx={{
                  height: 32,
                  "& .MuiToggleButton-root": {
                    textTransform: "none",
                    px: 2,
                    border: `1px solid ${Colors.gray[300]}`,
                    "&.Mui-selected": {
                      backgroundColor: Colors.blue[500],
                      color: "white",
                      "&:hover": {
                        backgroundColor: Colors.blue[600],
                      },
                    },
                  },
                }}
              >
                <ToggleButton value="kg">kg</ToggleButton>
                <ToggleButton value="lbs">lbs</ToggleButton>
              </ToggleButtonGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is1RM}
                    onChange={handleInputChange("is1RM")}
                    size="small"
                    sx={{
                      color: Colors.blue[500],
                      "&.Mui-checked": {
                        color: Colors.blue[500],
                      },
                    }}
                  />
                }
                label="1RM"
                sx={{
                  mr: 0,
                  ml: 1,
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    color: Colors.gray[700],
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              alignItems: "center",
            }}
          >
            {isAdmin ? (
              <Box sx={{ position: "relative" }} onClick={handleBoxClick}>
                <TextField
                  size="small"
                  label="Exercise"
                  value={formData.exercise}
                  onChange={handleInputChange("exercise")}
                  onKeyDown={handleSearchKeyDown}
                  placeholder="Search or enter exercise name"
                  sx={{ minWidth: 200, flex: 2 }}
                  disabled={!!editingRecord}
                  InputProps={{
                    endAdornment: editingRecord ? null : (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSearch}
                          edge="end"
                          size="small"
                          type="button"
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {showResults &&
                  exercises &&
                  exercises.length > 0 &&
                  !editingRecord && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        mt: 1,
                        maxHeight: 200,
                        overflow: "auto",
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        border: `1px solid ${Colors.gray[200]}`,
                        zIndex: 1000,
                      }}
                    >
                      {exercises.map((exercise) => (
                        <Box
                          key={exercise.id}
                          sx={{
                            p: 1.5,
                            cursor: "pointer",
                            "&:hover": {
                              bgcolor: Colors.gray[50],
                            },
                            borderBottom: `1px solid ${Colors.gray[100]}`,
                          }}
                          onClick={() => handleSelectExercise(exercise)}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {exercise.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {exercise.exerciseType}
                            {exercise.primaryMuscleGroup &&
                              ` • ${exercise.primaryMuscleGroup}`}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
              </Box>
            ) : (
              <TextField
                size="small"
                label="Exercise Name"
                value={formData.exercise}
                onChange={handleInputChange("exercise")}
                placeholder="e.g. Bench Press, Deadlift, Squat"
                sx={{ minWidth: 180, flex: 2 }}
                disabled={!!editingRecord}
              />
            )}
            <TextField
              size="small"
              label={`Weight (${unit})`}
              type="number"
              value={formData.weight}
              onChange={handleInputChange("weight")}
              inputProps={{
                step: "any",
                inputMode: "decimal",
              }}
              sx={{ width: 120 }}
            />
            {!formData.is1RM && (
              <TextField
                size="small"
                label="Reps"
                type="number"
                value={formData.reps}
                onChange={handleInputChange("reps")}
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                sx={{ width: 80 }}
              />
            )}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                ml: "auto",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !formData.exercise ||
                  !formData.weight ||
                  (!formData.is1RM && !formData.reps)
                }
                startIcon={editingRecord ? <SaveIcon /> : undefined}
                size="small"
                sx={{
                  backgroundColor: Colors.blue[500],
                  "&:hover": {
                    backgroundColor: Colors.blue[600],
                  },
                  textTransform: "none",
                }}
              >
                {editingRecord ? "Save" : "Add"}
              </Button>
              {editingRecord && (
                <Button
                  variant="outlined"
                  onClick={handleCancelEdit}
                  startIcon={<CloseIcon />}
                  size="small"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          border: `1px solid ${Colors.gray[200]}`,
          borderRadius: 1,
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 450px)",
            minHeight: "300px",
          }}
        >
          <Table stickyHeader size="medium">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: Colors.gray[50],
                    borderBottom: `2px solid ${Colors.gray[200]}`,
                  }}
                >
                  Exercise
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: Colors.gray[50],
                    borderBottom: `2px solid ${Colors.gray[200]}`,
                  }}
                >
                  Record
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: Colors.gray[50],
                    borderBottom: `2px solid ${Colors.gray[200]}`,
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    width: 100,
                    backgroundColor: Colors.gray[50],
                    borderBottom: `2px solid ${Colors.gray[200]}`,
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={5}
          rowsPerPageOptions={[]}
          sx={{
            borderTop: `1px solid ${Colors.gray[200]}`,
            ".MuiTablePagination-displayedRows": {
              fontSize: "14px",
            },
          }}
        />
      </Paper>

      <Dialog
        open={deleteConfirmation !== null}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 400,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle>Delete Personal Best</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this personal best record?
          </Typography>
          {deleteConfirmation && (
            <Box
              sx={{ mt: 2, p: 2, bgcolor: Colors.gray[50], borderRadius: 1 }}
            >
              <Typography variant="body2" color="text.secondary">
                Exercise: {deleteConfirmation.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Record: {formatRecordValue(deleteConfirmation)}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            sx={{ textTransform: "none" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
