import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "shared/themes";
import { UserPersonalRecords } from "./UserPersonalRecords";

interface UserPersonalRecordsModalProps {
  open: boolean;
  onClose: () => void;
  userId: number;
}

export const UserPersonalRecordsModal: React.FC<
  UserPersonalRecordsModalProps
> = ({ open, onClose, userId }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${Colors.gray[200]}`,
          p: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Personal Bests
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: Colors.gray[500],
            "&:hover": {
              color: Colors.gray[700],
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ height: "100%", overflowY: "auto" }}>
          <UserPersonalRecords userId={userId} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
