import React, { FC, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Colors } from "shared/themes";
import {
  useGetWorkoutProgramTemplatesQuery,
  WorkoutProgramItem,
  useCopyWorkoutProgramTemplateMutation,
  WorkoutProgramStatus,
} from "shared/api";
import { sharedStyles } from "shared/themes/shared/styles";
import { createAllUsersRows } from "shared/helpers";
import { AssignTemplateToUserModal } from "components/Modals/AssignTemplateToUserModal";
import { ArchiveWorkoutProgramTemplate } from "components/Modals/ArchiveWorkoutProgramTemplate";
import { ProgramsSkeleton } from "./ProgramsSkeleton";
import { ProgramTemplateItemActions } from "./ProgramTemplateItemActions";

type ProgramRow = {
  id: number;
  nameOfProgram: string;
  date: string;
  workoutProgramStatus: string;
  userId: number;
  userFullName: string;
};

const ProgramTemplatesContainer: FC = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [selectedWorkoutProgram, setSelectedWorkoutProgram] =
    useState<WorkoutProgramItem>();
  const [assignUserModal, setAssignUserModal] = useState(false);
  const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);

  const {
    data: workoutPrograms,
    isLoading: isLoadingWorkouts,
    refetch: refetchWorkoutPrograms,
  } = useGetWorkoutProgramTemplatesQuery({
    count: pageSize,
    page,
  });

  const [copyWorkoutProgramTemplate] = useCopyWorkoutProgramTemplateMutation();

  const filteredWorkoutPrograms = useMemo(() => {
    return (
      workoutPrograms?.filter(
        (workoutProgram) =>
          workoutProgram.workoutProgramStatus !== WorkoutProgramStatus.UNDEFINED
      ) ?? []
    );
  }, [workoutPrograms]);

  const [rows, setRows] = useState<ProgramRow[]>([]);
  const [rowCountState, setRowCountState] = useState(0);

  useEffect(() => {
    refetchWorkoutPrograms();
  }, []);

  useEffect(() => {
    if (!filteredWorkoutPrograms || isLoadingWorkouts) return;
    const rowsData = createAllUsersRows(filteredWorkoutPrograms);
    setRows(rowsData);
    setRowCountState(filteredWorkoutPrograms.length);
  }, [filteredWorkoutPrograms, isLoadingWorkouts]);

  const handleCopyTemplate = async (workoutProgramId: number) => {
    try {
      await copyWorkoutProgramTemplate({
        workoutProgramTemplateId: workoutProgramId,
      }).unwrap();
      refetchWorkoutPrograms();
    } catch (error) {
      // Handle error if needed
    }
  };

  const columns: GridColDef[] = [
    {
      field: "nameOfProgram",
      headerName: t("programs.templates.name"),
      flex: 2,
      sortable: false,
    },
    {
      field: "trainingSplit",
      headerName: t("programs.templates.training-split"),
      flex: 2,
      sortable: false,
    },
    {
      field: "createdDate",
      headerName: t("programs.templates.date"),
      flex: 2,
      sortable: false,
    },
    {
      field: "menu",
      headerName: "",
      minWidth: 50,
      flex: 0,
      sortable: false,
      renderCell: (params) => {
        return (
          <ProgramTemplateItemActions
            workoutProgramId={params.row.id}
            onAssignUserClick={() => {
              setSelectedWorkoutProgram(params.row);
              setAssignUserModal(true);
            }}
            onArchiveClick={() => {
              setSelectedWorkoutProgram(params.row);
              setDeleteTemplateModal(true);
            }}
            onCopyClick={() => handleCopyTemplate(params.row.id)}
          />
        );
      },
      filterable: false,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "800px",
        bgcolor: `${Colors.gray[100]}`,
        borderRadius: "8px",
        padding: "32px",
        mb: "16px",
      }}
    >
      {!isLoadingWorkouts ? (
        <Box sx={{ height: "700px" }}>
          <Box sx={sharedStyles.containers.dataGrid.secondaryWrapper}>
            <Typography sx={sharedStyles.headings.dataGrid}>
              Templates
            </Typography>
          </Box>
          <DataGrid
            headerHeight={56}
            rowHeight={52}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            rows={rows ?? []}
            columns={columns}
            rowCount={rowCountState}
            paginationMode="server"
            onRowClick={(params) => {
              setSelectedWorkoutProgram(params.row);
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                visibility: "hidden",
              },
              "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                bgcolor: `${Colors.gray[1700]}`,
              },
              "& .MuiDataGrid-menuIconButton": {
                display: "none",
              },
            }}
          />
          <AssignTemplateToUserModal
            isOpenModal={assignUserModal}
            handleCloseModal={() => setAssignUserModal(!assignUserModal)}
            workoutProgram={selectedWorkoutProgram}
          />
          <ArchiveWorkoutProgramTemplate
            isOpenModal={deleteTemplateModal}
            handleCloseModal={() => {
              setDeleteTemplateModal(false);
            }}
            workoutProgram={selectedWorkoutProgram}
          />
        </Box>
      ) : (
        <ProgramsSkeleton />
      )}
    </Box>
  );
};
export { ProgramTemplatesContainer };
