/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  TimelineItem,
  useGetTimelineItemsCountQuery,
  useGetTimelineItemsQuery,
  useGetTimelineByIdQuery,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  Skeleton,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Colors } from "shared/themes";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { TimelineViewItemCard } from "../TimelineViewItemCard";
import { AthleteTimelineViewInput } from "./AthleteTimelineViewInput";

const PAGE_SIZE = 5;
const SCROLL_IS_NEAR_TOP_OFFSET = 40;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AthleteTimelineViewProps {
  open: boolean;
  onClose: () => void;
  timelineId: number;
}

const AthleteTimelineView: FC<AthleteTimelineViewProps> = ({
  open,
  onClose,
  timelineId,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [loadedItems, setLoadedItems] = useState<TimelineItem[]>([]);
  const [showLoadMoreButton, setShowLoadMore] = useState(false);
  const [replyTo, setReplyTo] = useState<TimelineItem>();
  const [isReply, setIsReply] = useState(false);

  const { data: timeline, isLoading: isLoadingTimeline } =
    useGetTimelineByIdQuery(timelineId ?? 0, { skip: !timelineId });

  const { data: timelineItemsDataForCount, isLoading: isLoadingItemsLength } =
    useGetTimelineItemsCountQuery({
      timelineId,
    });
  const timelineItemsLength = timelineItemsDataForCount || 0;
  const hasNextPage = timelineItemsLength > page * pageSize;
  const timelineItemsRef = useRef<HTMLDivElement>(null);

  const { data: timelineItemsData, isLoading: isLoadingItems } =
    useGetTimelineItemsQuery({
      timelineId,
      orderByDescending: true,
      count: pageSize,
      page,
    });

  const checkForLoadMore = () => {
    const element = timelineItemsRef.current;
    return element ? element.scrollTop <= SCROLL_IS_NEAR_TOP_OFFSET : false;
  };

  const handleScroll = () => {
    const isScrollNearTop = checkForLoadMore();
    setShowLoadMore(isScrollNearTop);
  };

  const scollToBottom = () => {
    const element = timelineItemsRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    scollToBottom();
  }, []);

  useEffect(() => {
    if (timelineItemsData) {
      setLoadedItems((prevLoadedItems) => [
        ...prevLoadedItems,
        ...timelineItemsData,
      ]);
    }
  }, [timelineItemsData]);

  useEffect(() => {
    if (timelineItemsData && page === 1) {
      setLoadedItems(timelineItemsData);
      setShowLoadMore(true);
      scollToBottom();
    }
  }, [timelineItemsData]);

  useEffect(() => {
    if (loadedItems.length > 0 && page === 1) {
      scollToBottom();
    }
  }, [loadedItems, page]);

  const handleLoadMore = () => {
    if (!isLoadingItems && hasNextPage) {
      setPage(page + 1);
      const isScrollNearTop = checkForLoadMore();
      setShowLoadMore(isScrollNearTop);
    }
  };

  const handleAddItem = () => {
    scollToBottom();
    setPage(1);
    setLoadedItems([]);
    setReplyTo(undefined);
    setIsReply(false);
  };

  const handleReplyComment = (timelineItem: TimelineItem) => {
    if (timelineItem?.id === replyTo?.id) {
      setReplyTo(undefined);
      setIsReply(false);
      return;
    }
    setReplyTo(timelineItem);
    setIsReply(true);
  };

  if (isLoadingItems || isLoadingTimeline || isLoadingItemsLength)
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            pt: 6,
            background: `linear-gradient(
              126.67deg,
              #8B44AD 0%,
              #6C5CE7 50.52%,
              #4834D4 100%
            )`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
              Loading...
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 2 }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: "131px", width: "100%", mb: "12px" }}
          />
        </Box>
      </Dialog>
    );

  const timelineUserIdRequiredForWorkoutPreview =
    timeline?.timelineType === "Private" && timeline?.userIds.length
      ? timeline.userIds[0]
      : 0;

  const timelineName =
    timeline?.timelineType === "Private" ? "My Timeline" : timeline?.name;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          pt: 6,
          background: `linear-gradient(
            126.67deg,
            #8B44AD 0%,
            #6C5CE7 50.52%,
            #4834D4 100%
          )`,
        }}
      >
        <Toolbar
          sx={{ px: 3, display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 500,
              width: "120px",
            }}
            variant="h6"
          >
            {timelineName}
          </Typography>
          {hasNextPage && showLoadMoreButton ? (
            <PrimaryButton
              size="small"
              type="button"
              value={isLoadingItems ? "Loading..." : t("timeline.load-more")}
              loading={isLoadingItems}
              disabled={isLoadingItems}
              variant="contained"
              onClick={handleLoadMore}
              sx={{
                textTransform: "none",
                height: "36px",
                minWidth: "120px",
                backgroundColor: "white",
                color: Colors.blue[1500],
                borderColor: "white",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
                "&.Mui-disabled": {
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  color: Colors.blue[1500],
                },
                "& .MuiCircularProgress-root": {
                  color: Colors.blue[1500],
                },
              }}
            />
          ) : (
            <Box sx={{ width: "120px" }} />
          )}
          <Box
            sx={{ width: "40px", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          width: "100%",
          background: "white",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            p: 2,
            gap: 1,
          }}
          ref={timelineItemsRef}
          onScroll={handleScroll}
        >
          {!isLoadingItems &&
            loadedItems.map((item, index) => (
              <TimelineViewItemCard
                timelineItem={item}
                key={index}
                isClientView
                onReply={handleReplyComment}
                timelineUserId={timelineUserIdRequiredForWorkoutPreview}
              />
            ))}
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            borderTop: `1px solid ${Colors.gray[200]}`,
            position: "sticky",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <AthleteTimelineViewInput
            timelineId={timelineId ?? 0}
            onAddItem={handleAddItem}
            replyTo={replyTo}
            isReply={isReply}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export { AthleteTimelineView };
