import { FC, useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetUserProfileItemQuery,
  useUpdateUserProfileMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { useTypedSelector } from "shared/stores";
import { EmplolyeeUserProfileInsightPage } from "./EmplolyeeUserProfileInsightPage";

interface EmployeeUserProfileProps {
  closeSidebar: () => void;
}

const EmployeeUserProfile: FC<EmployeeUserProfileProps> = ({
  closeSidebar,
}) => {
  const matches = useMediaQuery("(min-width:900px)");

  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );

  const showSidebar = matches && isOpenSidebar;

  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userStatus: "",
  });
  const [displayName, setDisplayName] = useState({
    firstName: "",
    lastName: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const params = useParams();
  const { data: currentUserData, isLoading: isLoadingCurrentUserData } =
    useGetUserProfileItemQuery(Number(params.userId));

  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (currentUserData) {
      setFormState({
        email: currentUserData.email || "",
        firstName: currentUserData.firstName || "",
        lastName: currentUserData.lastName || "",
        phoneNumber: currentUserData.phoneNumber || "",
        userStatus: currentUserData.userStatus || "",
      });
      setDisplayName({
        firstName: currentUserData.firstName || "",
        lastName: currentUserData.lastName || "",
      });
    }
    return undefined;
  }, [currentUserData]);

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [successMessage, errorMessage]);

  const handleInputChange = (field: string, value: string) => {
    setFormState((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await updateUserProfile({
        ...formState,
        id: currentUserData?.id,
      }).unwrap();
      setDisplayName({
        firstName: formState.firstName,
        lastName: formState.lastName,
      });
      setSuccessMessage("Profile updated successfully.");
    } catch (error) {
      setErrorMessage("Failed to update profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoadingCurrentUserData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: showSidebar ? "calc(100% - 220px)" : "100%",
        overflowY: "scroll",
        position: "absolute",
        right: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "60px 20px 27px 24px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <IdenticonAvatar
              sizeValue={40}
              seedValue={currentUserData?.id.toString()}
              profileImageUrl={currentUserData?.profileImageUrl || ""}
              allowUpload
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "140%",
              textTransform: "none",
              color: `${Colors.gray[900]}`,
              mb: "16px",
              mt: "26px",
              pl: 1,
            }}
          >
            {displayName.firstName} {displayName.lastName}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                value={formState.email}
                disabled
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                type="tel"
                value={formState.phoneNumber}
                disabled={isSaving}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                value={formState.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                disabled={isSaving}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={formState.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                disabled={isSaving}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Status
              </Typography>
              <Select
                fullWidth
                value={formState.userStatus}
                onChange={(e) =>
                  handleInputChange("userStatus", e.target.value)
                }
                size="small"
                disabled={isSaving}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  {isSaving ? "Saving..." : "Save"}
                </Button>
                {isSaving && <CircularProgress size={24} />}
              </Box>
              {successMessage && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {successMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {errorMessage}
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mt: 4, mb: 4 }} />
        {currentUserData?.id && currentUserData.userStatus === "Active" && (
          <EmplolyeeUserProfileInsightPage userId={currentUserData.id} />
        )}
      </Box>
    </Box>
  );
};

export { EmployeeUserProfile };
