import { FC, useState } from "react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { sharedStyles } from "shared/themes/shared/styles";
import { useTypedSelector } from "shared/stores";
import {
  useGetChallengeQuery,
  useGetChallengeLeaderboardQuery,
  useUpdateChallengeMutation,
  UpdateChallengeParams,
  useAddUsersToChallengeMutation,
  useRemoveUsersFromChallengeMutation,
  ChallengeUser,
  ChallengeLeaderboardItem,
} from "shared/api";
import { Routes } from "shared/routers";
import { getGraphicSvg } from "shared/themes";

interface ChallengeContainerProps {
  openSidebar: () => void;
}

const ChallengeContainer: FC<ChallengeContainerProps> = ({ openSidebar }) => {
  const params = useParams();
  const challengeId = Number(params.id);
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [displayButtons, setDisplayButtons] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<ChallengeUser[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const [challengeData, setChallengeData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    targetValue: "",
    category: "",
  });

  const { data: challenge, isLoading: isChallengeLoading } =
    useGetChallengeQuery(challengeId);

  const { data: leaderboard, isLoading: isLeaderboardLoading } =
    useGetChallengeLeaderboardQuery(challengeId);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChallengeData((prev) => ({ ...prev, [name]: value }));
  };

  const [updateChallenge] = useUpdateChallengeMutation();
  const [addUsersToChallenge] = useAddUsersToChallengeMutation();
  const [removeUsersFromChallenge] = useRemoveUsersFromChallengeMutation();

  const handleSave = () => {
    const updatedChallenge: UpdateChallengeParams = {
      id: challengeId,
      name: challengeData.name,
      description: challengeData.description,
      targetValue: Number(challengeData.targetValue),
    };

    updateChallenge(updatedChallenge);
  };

  const handleAddUsers = async () => {
    try {
      setIsUpdating(true);
      await addUsersToChallenge({
        challengeId,
        challengeUsers: selectedUsers,
      }).unwrap();
      setSuccessMessage("Users successfully added to the challenge.");
      setIsAddDialogOpen(false);
    } catch (error) {
      setErrorMessage("Failed to add users to the challenge.");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemoveUsers = async () => {
    try {
      setIsUpdating(true);
      await removeUsersFromChallenge({
        challengeId,
        userIds: selectedUserIds,
      }).unwrap();
      setSuccessMessage("Users successfully removed from the challenge.");
      setIsRemoveDialogOpen(false);
    } catch (error) {
      setErrorMessage("Failed to remove users from the challenge.");
    } finally {
      setIsUpdating(false);
    }
  };

  if (isChallengeLoading || isLeaderboardLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={{ mb: 2 }}>
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>
            {challenge?.name} -{" "}
            {dayjs(challenge?.startDate).format("DD MMM YYYY")} -{" "}
            {dayjs(challenge?.endDate).format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Box sx={{ mt: "-12px" }}>
          <Typography variant="body2" color="text.secondary">
            {challenge?.description}
          </Typography>
        </Box>
      </Box>
      {!leaderboard && (
        <Box sx={{ mt: 4 }}>
          <Box
            component="img"
            src={getGraphicSvg("thinking")}
            alt="Thinking"
            sx={{ height: "110px", mb: 2 }}
          />
          <Typography variant="body2" color="text.secondary">
            There is no data available for this challenge yet. Data can take up
            to 72 hours to appear.
          </Typography>
        </Box>
      )}

      {leaderboard && (
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                width: "100%",
              }}
            >
              <CardContent>
                <Typography variant="h6">Leaderboard</Typography>
                {displayButtons && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => setIsAddDialogOpen(true)}
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Add Users
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setIsRemoveDialogOpen(true)}
                      sx={{ mt: 2 }}
                    >
                      Remove Users
                    </Button>
                  </>
                )}
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Rank</TableCell>
                        <TableCell>Participant</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leaderboard
                        ?.filter((x) => x.value && x.value > 0)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.rank}</TableCell>
                            <TableCell>
                              <Link
                                to={`${Routes.profile.url}/${item.userId}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    src={item.profileImageUrl}
                                    alt={`${item.firstName} ${item.lastName}`}
                                    sx={{ mr: 2 }}
                                  />
                                  <Typography>
                                    {item.firstName} {item.lastName}
                                  </Typography>
                                </Box>
                              </Link>
                            </TableCell>
                            <TableCell>
                              {item.value?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Add Users to Challenge</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            options={[]} // Replace with the list of users
            getOptionLabel={(option: ChallengeUser) =>
              `${option.id} - ${option.userId}`
            }
            onChange={(event, value) => setSelectedUsers(value)}
            renderInput={(params) => (
              <TextField {...params} label="Select Users" fullWidth />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddUsers} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isRemoveDialogOpen}
        onClose={() => setIsRemoveDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Remove Users from Challenge</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            options={leaderboard || []}
            getOptionLabel={(option: ChallengeLeaderboardItem) =>
              `${option.userId} - ${option.firstName} ${option.lastName}`
            }
            onChange={(event, value) =>
              setSelectedUserIds(value.map((user) => user.userId))
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Users" fullWidth />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRemoveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRemoveUsers} variant="contained" color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { ChallengeContainer };
