import { FC, useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import { useTypedSelector } from "shared/stores";
import { userSelectors } from "shared/stores/user";
import { Sidebar } from "shared/ui";
import { Header as AdminHeader } from "../Dashboard/AdminDashboard/ui/Header";

interface LayoutProps {
  children: React.ReactNode;
  isOpenSidebar: boolean;
  closeSidebar?: () => void;
  toggleSidebar: () => void;
  openSidebar: () => void;
}

const Layout: FC<LayoutProps> = ({
  children,
  isOpenSidebar,
  closeSidebar,
  toggleSidebar,
  openSidebar,
}) => {
  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);

  const organisationType = useTypedSelector(userSelectors.organisationType);

  const [isMobileApp, setIsMobileApp] = useState(false);

  const checkDeviceIsMobileApp = async () => {
    const device = await Device.getInfo();
    if (device.platform === "android" || device.platform === "ios") {
      setIsMobileApp(true);
      return;
    }
    setIsMobileApp(false);
  };

  useEffect(() => {
    checkDeviceIsMobileApp();
  }, []);

  const renderHeader = () => {
    if (
      isAdminOrCoach &&
      organisationType === "WorkplaceWellbeing" &&
      isMobileApp
    ) {
      return null;
    }

    if (isAdminOrCoach) {
      return <AdminHeader toggleSidebar={toggleSidebar} />;
    }

    return null;
  };

  return (
    <>
      <Sidebar
        openSidebar={openSidebar}
        isOpenSidebar={isOpenSidebar}
        closeSidebar={closeSidebar}
      />
      {renderHeader()}
      {children}
    </>
  );
};

export { Layout };
